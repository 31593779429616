import {
  TRANSITION_ACCEPT_OFFER,
  TRANSITION_AUTO_REJECT_OFFER,
  TRANSITION_CONFIRM_OFFER_PAYMENT,
  TRANSITION_REJECT_OFFER,
  TRANSITION_REQUEST_PAYMENT,
} from './transaction';
import { OfferStatusEnum } from './enum';

export const getRecentlyViewedIds = (currentUser, currentListingId) => {
  const recentlyViewed =
    currentUser?.attributes?.profile?.privateData?.recentlyViewed ||
    JSON.parse((typeof window !== 'undefined' && localStorage.getItem('recentlyViewed')) || '[]');
  return recentlyViewed
    .filter(item => item.listingId !== currentListingId)
    .sort((a, b) => b.timestamp - a.timestamp)
    .map(item => item.listingId);
};

export const getImageSize = file => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  const img = document.createElement('img');

  return new Promise((resolve, reject) => {
    reader.onload = e => {
      const url = e.target.result;
      img.onload = () => {
        // Natural size is the actual image size regardless of rendering.
        // The 'normal' `width`/`height` are for the **rendered** size.
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        // Resolve promise with the width and height
        resolve({ width, height });
      };
      // Reject promise on error
      img.onerror = reject;

      // Setting the source makes it start downloading and eventually call `onload`
      img.src = url;
    };
    reader.onerror = reject;
  });
};

export const isSavedAddressEqual = (address, otherAddress) => {
  return (
    address &&
    otherAddress &&
    address.addressLine1 === otherAddress.addressLine1 &&
    address.addressLine2 === otherAddress.addressLine2 &&
    address.city === otherAddress.city &&
    address.state === otherAddress.state &&
    address.country === otherAddress.country &&
    address.postal === otherAddress.postal
  );
};

export const getOfferStatus = transaction => {
  const lastTransition = transaction?.attributes.lastTransition;
  const offerPrice = transaction?.attributes.protectedData?.offerPrice;
  if (!!offerPrice) {
    if (
      lastTransition === TRANSITION_CONFIRM_OFFER_PAYMENT ||
      lastTransition === TRANSITION_REQUEST_PAYMENT
    ) {
      return OfferStatusEnum.PENDING;
    } else if (
      lastTransition === TRANSITION_REJECT_OFFER ||
      lastTransition === TRANSITION_AUTO_REJECT_OFFER
    ) {
      return OfferStatusEnum.REJECTED;
    } else {
      return OfferStatusEnum.ACCEPTED;
    }
  }
  return '';
};

export const humanizeSnakeCase = str =>
  str
    .replace(/_+/g, ' ')
    .toLowerCase()
    .replace(/^./, str => str.toUpperCase());
