import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaPinterest.module.css';

const IconSocialMediaPinterest = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'
         className={classes}>
      <path
        d='M1 17C1 23.8307 5.28133 29.6627 11.3067 31.9573C11.16 30.708 11.004 28.648 11.34 27.2027C11.6293 25.96 13.208 19.2853 13.208 19.2853C13.208 19.2853 12.732 18.332 12.732 16.92C12.732 14.7067 14.0147 13.0533 15.6133 13.0533C16.9733 13.0533 17.6293 14.0733 17.6293 15.296C17.6293 16.6627 16.7587 18.7053 16.3093 20.6C15.9347 22.1853 17.1053 23.4787 18.668 23.4787C21.4987 23.4787 23.676 20.4933 23.676 16.184C23.676 12.3693 20.9347 9.704 17.0213 9.704C12.4907 9.704 9.83067 13.1027 9.83067 16.616C9.83067 17.9853 10.3573 19.452 11.016 20.2507C11.0722 20.311 11.1118 20.3847 11.1312 20.4648C11.1506 20.5449 11.149 20.6287 11.1267 20.708C11.0053 21.212 10.736 22.2933 10.684 22.5147C10.6133 22.8053 10.4533 22.868 10.1507 22.7267C8.16133 21.8013 6.91867 18.8933 6.91867 16.5573C6.91867 11.532 10.568 6.91867 17.4413 6.91867C22.9667 6.91867 27.2613 10.856 27.2613 16.1173C27.2613 21.6067 23.8013 26.0253 18.996 26.0253C17.3813 26.0253 15.8653 25.1853 15.3453 24.1947C15.3453 24.1947 14.5467 27.2373 14.3533 27.9813C13.9773 29.4267 12.9347 31.256 12.288 32.2947C13.7787 32.7533 15.36 33 17 33C25.836 33 33 25.836 33 17C33 8.164 25.836 1 17 1C8.164 1 1 8.164 1 17Z'
        fill='#4A4A4A' />
    </svg>
  );
};

IconSocialMediaPinterest.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaPinterest.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaPinterest;
