import React, { Component, useState, useEffect } from 'react';
import { string, func, bool } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { fetchCurrentUser } from '../../ducks/user.duck';
import css from './ListingCard.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const [isFav, setFav] = useState(false);
  const [fontAw, setFontAwsome] = useState(AiOutlineHeart);

  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    onUpdateLike,
    currentUser,
    history,
    isAuthenticated,
    data,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { isTrader } = currentUser?.attributes.profile.publicData || {};

  const { title = '', price, publicData } = currentListing?.attributes || {};
  const { isSale, oldPrice, tradePrice } = publicData || {};
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author?.attributes.profile.displayName;
  const authorId = author.id.uuid;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const { formattedPrice, priceTitle } = priceData(price, intl);
  const oldPriceMoney = isSale && oldPrice ? new Money(oldPrice.amount, oldPrice.currency) : null;
  const { formattedPrice: formattedOldPrice } =
    oldPriceMoney && price.amount < oldPriceMoney.amount ? priceData(oldPriceMoney, intl) : {};
  const discountPercent = formattedOldPrice
    ? Math.round((1 - price.amount / oldPriceMoney.amount) * 100)
    : null;
  const tradeMoney =
    isTrader && tradePrice ? new Money(tradePrice?.amount, tradePrice?.currency) : null;
  const { formattedPrice: formattedTradePrice } =
    tradeMoney && price.amount > tradeMoney?.amount ? priceData(tradeMoney, intl) : {};
  const unitType = config.lineItemUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  return (
    <div>
      <NamedLink
        className={classes}
        name="ListingPage"
        params={{ id, slug }}
        currentListing={currentListing}
      >
        <AspectRatioWrapper
          className={css.aspectRatioWrapper}
          width={aspectWidth}
          height={aspectHeight}
          {...setActivePropsMaybe}
        >
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={variants}
            sizes={renderSizes}
          />
        </AspectRatioWrapper>
      </NamedLink>
      <div className={css.info}>
        <div className={css.priceWrapper}>
          <div className={css.price}>
            {formattedOldPrice ? (
              <div className={classNames(css.priceValue, css.oldPriceValue)} title={priceTitle}>
                {formattedOldPrice}
              </div>
            ) : (
              <></>
            )}
            <div className={css.priceValue} title={priceTitle}>
              {formattedPrice}
            </div>
          </div>
          {discountPercent ? <div className={css.discount}>-{discountPercent}%</div> : <></>}
        </div>
        <div className={css.mainInfo}>
          {isTrader && !!tradeMoney && (
            <p className={css.traderPrice}>
              Trade price: <span>{formattedTradePrice}</span>
            </p>
          )}
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div className={css.priceWrapper}>
            {showAuthorInfo ? (
              <NamedLink className={css.link} name="ProfilePage" params={{ id: authorId }}>
                <div className={css.authorInfo}>
                  <FormattedMessage id="ListingCard.author" values={{ authorName }} />
                </div>
              </NamedLink>
            ) : null}
            <div
              className={css.priceValue}
              title={priceTitle}
              style={{ cursor: 'pointer' }}
              onClick={() => onUpdateLike(id)}
            >
              {data && data.likedListings ? (
                data.likedListings.includes(id) ? (
                  <AiFillHeart />
                ) : (
                  <AiOutlineHeart />
                )
              ) : (
                <AiOutlineHeart />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
