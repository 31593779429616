// ================ Action types ================ //
import { fetchCurrentUser } from '../../ducks/user.duck';
import { isSavedAddressEqual } from '../../util/helpers';

export const UPDATE_ADDRESS_LIST_REQUEST = 'app/FollowingUsers/UPDATE_ADDRESS_LIST_REQUEST';
export const UPDATE_ADDRESS_LIST_SUCCESS = 'app/FollowingUsers/UPDATE_ADDRESS_LIST_SUCCESS';
export const UPDATE_ADDRESS_LIST_ERROR = 'app/FollowingUsers/UPDATE_ADDRESS_LIST_ERROR';
export const UPDATE_IS_FIRST_ADDRESS = 'app/FollowingUsers/UPDATE_IS_FIRST_ADDRESS';

// ================ Reducer ================ //

const initialState = {
  addressList: [],
  addressListInProgress: true,
  addressListError: null,
  isFirstAddress: false,
};

const profileFollowingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_ADDRESS_LIST_REQUEST: {
      return { ...state, addressListInProgress: true, addressListError: null };
    }
    case UPDATE_ADDRESS_LIST_SUCCESS: {
      return {
        ...state,
        addressList: payload,
        addressListInProgress: false,
      };
    }
    case UPDATE_ADDRESS_LIST_ERROR: {
      return { ...state, addressListInProgress: false, addressListError: payload };
    }
    case UPDATE_IS_FIRST_ADDRESS: {
      return { ...state, isFirstAddress: payload };
    }
    default:
      return state;
  }
};

export default profileFollowingPageReducer;

// ================ Action creators ================ //

export const updateAddressListRequest = () => ({
  type: UPDATE_ADDRESS_LIST_REQUEST,
});
export const updateAddressListSuccess = payload => ({
  type: UPDATE_ADDRESS_LIST_SUCCESS,
  payload,
});
export const updateAddressListError = error => ({
  type: UPDATE_ADDRESS_LIST_ERROR,
  payload: error,
  error: true,
});
export const updateIsFirstAddress = payload => ({
  type: UPDATE_IS_FIRST_ADDRESS,
  payload,
});

// ================ Thunks ================ //

export const getAddressList = () => (dispatch, getState) => {
  return dispatch(fetchCurrentUser()).then(() => {
    const currentUser = getState().user.currentUser;
    const addressList = currentUser.attributes.profile.privateData?.addressList;
    dispatch(updateAddressListSuccess(addressList || []));
    if (!addressList) {
      dispatch(updateIsFirstAddress(true));
    }
  });
};

export const addNewAddress = newAddress => (dispatch, getState, sdk) => {
  dispatch(updateAddressListRequest());
  const currentAddressList = getState().AddressManagementPage.addressList;

  if (currentAddressList.some(address => isSavedAddressEqual(address, newAddress))) {
    dispatch(updateAddressListError('An identical address already exists'));
    return;
  }

  const addressList = currentAddressList ? [...currentAddressList, newAddress] : [newAddress];

  return sdk.currentUser.updateProfile({ privateData: { addressList: addressList } }).then(() => {
    dispatch(updateAddressListSuccess(addressList));
  });
};

export const updateAddress = (updatedAddress, id) => (dispatch, getState, sdk) => {
  dispatch(updateAddressListRequest());

  const currentAddressList = getState().AddressManagementPage.addressList;

  if (
    currentAddressList.some(
      address => address.id !== id && isSavedAddressEqual(address, updatedAddress)
    )
  ) {
    dispatch(updateAddressListError('An identical address already exists'));
    return;
  }

  const addressList = currentAddressList.map(currentAddress => {
    if (currentAddress.id === id) {
      return updatedAddress;
    }
    return currentAddress;
  });

  return sdk.currentUser.updateProfile({ privateData: { addressList: addressList } }).then(() => {
    dispatch(updateAddressListSuccess(addressList));
  });
};

export const deleteAddress = id => (dispatch, getState, sdk) => {
  dispatch(updateAddressListRequest());

  const currentAddressList = getState().AddressManagementPage.addressList;

  const addressList = currentAddressList.filter(address => address.id !== id);

  return sdk.currentUser
    .updateProfile({ privateData: { addressList: addressList, isFirstAddress: false } })
    .then(() => {
      dispatch(updateAddressListSuccess(addressList));
    });
};
