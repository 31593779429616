// These helpers are calling FTW's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import { types as sdkTypes, transit } from './sdkLoader';
import config from '../config';
import Decimal from 'decimal.js';
import axios from 'axios';

const rootUrl = process.env.REACT_APP_CANONICAL_ROOT_URL;

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`.trim();
  }

  // Otherwise, use the same domain and port as the frontend
  if (typeof window !== 'undefined') {
    return `${window.location.origin}`.trim();
  }

  return rootUrl?.trim() || '';
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const get = path => {
  const url = `${apiBaseUrl()}${path}`;
  return axios
    .request({
      method: 'GET',
      url,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/transit+json',
      },
    })
    .then(res => {
      const contentTypeHeader = res.headers['content-type'];
      const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

      if (res.status >= 400) {
        let e = new Error();
        e = Object.assign(e, res.data);

        throw e;
      }
      if (contentType === 'application/transit+json') {
        return deserialize(JSON.stringify(res.data));
      } else if (contentType === 'application/json') {
        return res.data;
      }
      return res.data;
    });
};

const post = (path, body, signal) => {
  const url = `${apiBaseUrl()}${path}`;
  return axios
    .request({
      method: 'POST',
      url,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/transit+json',
      },
      data: serialize(body),
      signal,
    })
    .then(res => {
      const contentTypeHeader = res.headers['content-type'];
      const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

      if (res.status >= 400) {
        let e = new Error();
        e = Object.assign(e, res.data);

        throw e;
      }
      if (contentType === 'application/transit+json') {
        return deserialize(JSON.stringify(res.data));
      } else if (contentType === 'application/json') {
        return res.data;
      }
      return res.data;
    });
};

const postMultiPart = (path, body, signal) => {
  const url = `${apiBaseUrl()}${path}`;
  return axios
    .request({
      method: 'POST',
      url,
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: body,
      signal,
    })
    .then(res => {
      const contentTypeHeader = res.headers['content-type'];
      const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

      if (res.status >= 400) {
        let e = new Error();
        e = Object.assign(e, res.data);

        throw e;
      }
      if (contentType === 'application/transit+json') {
        return deserialize(JSON.stringify(res.data));
      } else if (contentType === 'application/json') {
        return res.data;
      }
      return res.data;
    });
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export let initiatePrivilegedAbortController;
export const initiatePrivileged = body => {
  if (initiatePrivilegedAbortController) {
    initiatePrivilegedAbortController.abort();
  }
  initiatePrivilegedAbortController = new AbortController();
  return post('/api/initiate-privileged', body, initiatePrivilegedAbortController.signal);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export let transitionPrivilegedAbortController;
export const transitionPrivileged = body => {
  if (transitionPrivilegedAbortController) {
    transitionPrivilegedAbortController.abort();
  }
  transitionPrivilegedAbortController = new AbortController();
  return post('/api/transition-privileged', body, transitionPrivilegedAbortController.signal);
};

export const userFollow = body => {
  return post('/api/users/follow', body);
};

export const fetchUsers = params => {
  const paramsString = new URLSearchParams(params).toString();
  return get(`/api/users/showUsers${paramsString ? `?${paramsString}` : ''}`);
};

export const getUserById = ({ id }) => {
  return get(`/api/users/show/${id}`);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Flex with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

export const requestSellerApprove = () => {
  return post('/api/seller-approve/request');
};

export const requestTraderApprove = body => {
  return postMultiPart('/api/trader-approve/request', body);
};

export const getShippingPrice = body => {
  return post('/api/get-shipping-price', body);
};

export const createShipment = body => {
  return post('/api/shipment', body);
};

export const getTrackingInfo = id => {
  return get(`/api/shipment/${id}/track`);
};

export const newsletterSubscribe = body => {
  return post('/api/newsletter-subscribe', body);
};

export const newsletterUnsubscribe = body => {
  return post('/api/newsletter-unsubscribe', body);
};
export const newsletterIsSubscribed = body => {
  return post('/api/newsletter-is-subscribed', body);
};

export const acceptOffer = transactionId => {
  return post(`/new/api/transactions/${transactionId}/accept-offer`);
};
