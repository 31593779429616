import React, { useEffect, useState } from 'react';
import { intlShape } from '../../util/reactIntl';
import { bool, object, string, func, array } from 'prop-types';
import config from '../../config';
import * as validators from '../../util/validators';
import getCountryCodes from '../../translations/countryCodes';
import { FieldTextInput, FieldSelect, InlineTextButton } from '../../components';

import css from './StripePaymentAddress.module.css';

const StripePaymentAddress = props => {
  const {
    className,
    addressList,
    currentAddress,
    intl,
    disabled,
    form,
    fieldId,
    card,
    onPostalChange,
    location
  } = props;

  const optionalText = intl.formatMessage({
    id: 'StripePaymentAddress.optionalText',
  });

  const addressLine1Label = intl.formatMessage({
    id: 'StripePaymentAddress.addressLine1Label',
  });
  const addressLine1Placeholder = intl.formatMessage({
    id: 'StripePaymentAddress.addressLine1Placeholder',
  });
  const addressLine1Required = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.addressLine1Required',
    })
  );

  const addressLine2Label = intl.formatMessage(
    { id: 'StripePaymentAddress.addressLine2Label' },
    { optionalText: optionalText }
  );

  const addressLine2Placeholder = intl.formatMessage({
    id: 'StripePaymentAddress.addressLine2Placeholder',
  });

  const postalCodeLabel = intl.formatMessage({ id: 'StripePaymentAddress.postalCodeLabel' });
  const postalCodePlaceholder = intl.formatMessage({
    id: 'StripePaymentAddress.postalCodePlaceholder',
  });
  const postalCodeRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.postalCodeRequired',
    })
  );

  const cityLabel = intl.formatMessage({ id: 'StripePaymentAddress.cityLabel' });
  const cityPlaceholder = intl.formatMessage({ id: 'StripePaymentAddress.cityPlaceholder' });
  const cityRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.cityRequired',
    })
  );

  const statePlaceholder = intl.formatMessage({ id: 'StripePaymentAddress.statePlaceholder' });

  const countryLabel = intl.formatMessage({ id: 'StripePaymentAddress.countryLabel' });
  const countryPlaceholder = intl.formatMessage({ id: 'StripePaymentAddress.countryPlaceholder' });
  const countryRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.countryRequired',
    })
  );

  const selectAddress = (selectedAddress) => {
    const currentAddress = addressList.find(({ addressName }) => addressName === selectedAddress);

    if (currentAddress) {
      form.batch(() => {
        form.change('addressLine1', currentAddress.addressLine1);
        form.change('addressLine2', currentAddress.addressLine2);
        form.change('postal', currentAddress.postal);
        form.change('city', currentAddress.city);
        form.change('state', currentAddress.state);
        form.change('recipientCountry', currentAddress.country);
      });
      onPostalChange(currentAddress.postal);
    }
  };

  const addressNameRequired = validators.required(
    intl.formatMessage({
      id: 'AddressManagement.addressNameRequired',
    })
  );

  const timestamp = new Date().getTime().toString();
  const [showNewAddressForm, setShowNewAddressForm] = useState(false);
  const [isAddressManagement, setIsAddressManagement] = useState(false);

  const newAddress = timestamp => {
    form.restart();
    form.change('id', timestamp);
    form.change("isNewAddress", true);
    form.change('currentId', '');
    setShowNewAddressForm(true);
  };

  useEffect(() => {
    form.change('id', timestamp);
    form.change("isNewAddress", false);
    form.change('currentId', '');

    if (!addressList || !addressList.length) {
      form.change("isNewAddress", true);
    }
  }, [])

  useEffect(() => {
    if (location && location.pathname.includes('/profile-address-management')) {
      setIsAddressManagement(true);
    }
  }, [location?.pathname])

  useEffect(() => {
    if (currentAddress) {
      selectAddress(currentAddress);
    }

  }, [currentAddress]);

  const handleOnChange = event => {
    const value = event.target.value;
    form.change('postal', value);
    card && card.update({ value: { postalCode: value } });
    onPostalChange && onPostalChange(value);
  };

  // Use tha language set in config.locale to get the correct translations of the country names
  const countryCodes = getCountryCodes(config.locale);
  const unitedStatesList = config.unitedStates.map(state => state.code);

  const stateLabel = intl.formatMessage({ id: 'SellerForm.statePlaceholder' });
  const stateRequiredMessage = intl.formatMessage({ id: 'SellerForm.stateRequired' });
  const stateRequired = validators.required(stateRequiredMessage);

  return (
    <>
      { isAddressManagement ?
      (<>
        <div className={className ? className : css.root}>
          <div className={css.formRow}>
            <FieldTextInput
              id={`${fieldId}.addressLine1`}
              name="addressLine1"
              disabled={disabled}
              className={css.field}
              type="text"
              autoComplete="billing address-line1"
              label={addressLine1Label}
              placeholder={addressLine1Placeholder}
              validate={addressLine1Required}
              onUnmount={() => form.change('addressLine1', undefined)}
            />

            <FieldTextInput
              id={`${fieldId}.addressLine2`}
              name="addressLine2"
              disabled={disabled}
              className={css.field}
              type="text"
              autoComplete="billing address-line2"
              label={addressLine2Label}
              placeholder={addressLine2Placeholder}
              onUnmount={() => form.change('addressLine2', undefined)}
            />
          </div>
          <div className={css.formRow}>
            <FieldTextInput
              id={`${fieldId}.postalCode`}
              name="postal"
              disabled={disabled}
              className={css.field}
              type="text"
              autoComplete="billing postal-code"
              label={postalCodeLabel}
              placeholder={postalCodePlaceholder}
              validate={postalCodeRequired}
              onUnmount={() => form.change('postal', undefined)}
              onChange={event => handleOnChange(event)}
            />

            <FieldTextInput
              id={`${fieldId}.city`}
              name="city"
              disabled={disabled}
              className={css.field}
              type="text"
              autoComplete="billing address-level2"
              label={cityLabel}
              placeholder={cityPlaceholder}
              validate={cityRequired}
              onUnmount={() => form.change('city', undefined)}
            />
          </div>
          <div className={css.formRow}>
            <FieldSelect
              className={css.field}
              id="state"
              name="state"
              label={stateLabel}
              autoComplete="state"
              validate={stateRequired}
            >
              <option disabled value="">
                {intl.formatMessage({ id: 'SellerForm.statePlaceholder' })}
              </option>
              {unitedStatesList.map(state => (
                <option key={state} value={state}>
                  {intl.formatMessage({ id: `SellerForm.stateNames.${state}` })}
                </option>
              ))}
            </FieldSelect>
          </div>
        </div>
      </>) :
        (
          <div className={className ? className : css.root}>
            <InlineTextButton
              type="button"
              onClick={() => {
                newAddress(timestamp);
              }}
              className={css.addButton}
            >
              Add new address
            </InlineTextButton>

            {((Array.isArray(addressList) && !addressList.length) || showNewAddressForm) ? (
              <>
              <FieldTextInput
                id={`${fieldId}.addressName`}
                name="addressName"
                disabled={disabled}
                className={css.fieldFullWidth}
                type="text"
                autoComplete="addressManagement"
                label="Address name"
                placeholder="Home"
                validate={addressNameRequired}
                onUnmount={() => form.change('addressName', undefined)}
              />
                <div className={css.formRow}>
                  <FieldTextInput
                    id={`${fieldId}.addressLine1`}
                    name="addressLine1"
                    disabled={disabled}
                    className={css.field}
                    type="text"
                    autoComplete="billing address-line1"
                    label={addressLine1Label}
                    placeholder={addressLine1Placeholder}
                    validate={addressLine1Required}
                    onUnmount={() => form.change('addressLine1', undefined)}
                  />

                  <FieldTextInput
                    id={`${fieldId}.addressLine2`}
                    name="addressLine2"
                    disabled={disabled}
                    className={css.field}
                    type="text"
                    autoComplete="billing address-line2"
                    label={addressLine2Label}
                    placeholder={addressLine2Placeholder}
                    onUnmount={() => form.change('addressLine2', undefined)}
                  />
                </div>
                <div className={css.formRow}>
                  <FieldTextInput
                    id={`${fieldId}.postalCode`}
                    name="postal"
                    disabled={disabled}
                    className={css.field}
                    type="text"
                    autoComplete="billing postal-code"
                    label={postalCodeLabel}
                    placeholder={postalCodePlaceholder}
                    validate={postalCodeRequired}
                    onUnmount={() => form.change('postal', undefined)}
                    onChange={event => handleOnChange(event)}
                  />

                  <FieldTextInput
                    id={`${fieldId}.city`}
                    name="city"
                    disabled={disabled}
                    className={css.field}
                    type="text"
                    autoComplete="billing address-level2"
                    label={cityLabel}
                    placeholder={cityPlaceholder}
                    validate={cityRequired}
                    onUnmount={() => form.change('city', undefined)}
                  />
                </div>
                <div className={css.formRow}>
                  <FieldSelect
                    className={css.field}
                    id="state"
                    name="state"
                    label={stateLabel}
                    autoComplete="state"
                    validate={stateRequired}
                  >
                    <option disabled value="">
                      {intl.formatMessage({ id: 'SellerForm.statePlaceholder' })}
                    </option>
                    {unitedStatesList.map(state => (
                      <option key={state} value={state}>
                        {intl.formatMessage({ id: `SellerForm.stateNames.${state}` })}
                      </option>
                    ))}
                  </FieldSelect>
                </div>
              </>
              ) :
              (<></>)
            }
          </div>
        )
      }
    </>
  );
};
StripePaymentAddress.defaultProps = {
  country: null,
  disabled: false,
  fieldId: null,
  addressList: null,
  currentAddress: null
};

StripePaymentAddress.propTypes = {
  country: string,
  disabled: bool,
  form: object.isRequired,
  fieldId: string,
  addressList: array,
  currentAddress: string,
  location: object,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default StripePaymentAddress;
