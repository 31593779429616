import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.module.css';

const ProfileIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootMobileIcons, className);

  return (
    <svg className={classes} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="account" d="M15,30A15,15,0,1,1,30,15,15.017,15.017,0,0,1,15,30ZM15,1.85A13.149,13.149,0,1,0,28.149,15,13.164,13.164,0,0,0,15,1.85Zm0,2.137a6.237,6.237,0,1,0,6.237,6.237A6.244,6.244,0,0,0,15,3.988ZM20.534,16.5a8.346,8.346,0,0,1-11.067,0,11.1,11.1,0,0,0-4,4.009,11,11,0,0,0,19.06,0A11.1,11.1,0,0,0,20.534,16.5Z" fill="#262626"/>
    </svg>
  );
};

const { string } = PropTypes;

ProfileIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

ProfileIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default ProfileIcon;
