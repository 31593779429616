import React from 'react';
import css from './DeliveryTimeTip.module.css';
import classNames from 'classnames';

const DELIVERY_WEEKS = '4 weeks';

const DeliveryTimeTip = ({ className }) => {
  return (
    <div className={classNames(css.deliveryTimeTip, className)}>
      <p>Encore Society delivery is powered by uShip.</p>
      <p>Estimated delivery time: {DELIVERY_WEEKS}.</p>
    </div>
  );
};

export default DeliveryTimeTip;
