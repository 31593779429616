import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchUsers } from '../../util/api';
import { convertUsers } from './ArtistsPage.helpers';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages

// SearchList Pagination Changes From here

// ================ Action types ================ //

export const GET_ARTISTS_REQUEST = 'app/ArtistsPage/GET_ARTISTS_REQUEST';
export const GET_ARTISTS_SUCCESS = 'app/ArtistsPage/GET_ARTISTS_SUCCESS';
export const GET_ARTISTS_ERROR = 'app/ArtistsPage/GET_ARTISTS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  searchListingsError: null,
  artists: [],
  artistsSpotlight: [],
  page: 1,
  totalItems: 0,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ARTISTS_REQUEST:
      return {
        ...state,
        fetchInProgress: true,
        searchListingsError: null,
      };
    case GET_ARTISTS_SUCCESS:
      const page = payload.meta.page;
      return {
        ...state,
        artists: page > 1 ? [...state.artists, ...payload.artists] : payload.artists,
        totalItems: payload.meta.totalItems,
        page,
        artistsSpotlight: payload.artists.filter(
          ({ attributes }) => attributes.profile.publicData.isSpotlight
        ),
        fetchInProgress: false,
        searchListingsError: null,
      };
    case GET_ARTISTS_ERROR:
      return { ...state, fetchInProgress: false, searchListingsError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const getArtistsRequest = searchParams => ({
  type: GET_ARTISTS_REQUEST,
  payload: { searchParams },
});

export const getArtistsSuccess = payload => ({
  type: GET_ARTISTS_SUCCESS,
  payload,
});

export const getArtistsError = e => ({
  type: GET_ARTISTS_ERROR,
  error: true,
  payload: e,
});

const ARTISTS_PER_PAGE = 50;
export const getArtists = searchParams => dispatch => {
  dispatch(getArtistsRequest(searchParams));

  const { page = 1 } = searchParams || {};

  return fetchUsers({
    include: ['profileImage'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    pub_isArtist: true,
    page,
    perPage: ARTISTS_PER_PAGE,
  })
    .then(response => {
      const convertedArtists = convertUsers(response.data.data, response.data.included);
      dispatch(getArtistsSuccess({ artists: convertedArtists, meta: response.data.meta }));
    })
    .catch(e => getArtistsError(storableError(e)));
};

export const loadData = () => {
  return getArtists();
};
