import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaFacebook.module.css';

const IconSocialMediaFacebook = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'
         className={classes}>
      <path fillRule='evenodd' clipRule='evenodd'
            d='M2 18.0893C2 26.044 7.77733 32.6587 15.3333 34V22.444H11.3333V18H15.3333V14.444C15.3333 10.444 17.9107 8.22267 21.556 8.22267C22.7107 8.22267 23.956 8.4 25.1107 8.57733V12.6667H23.0667C21.1107 12.6667 20.6667 13.644 20.6667 14.8893V18H24.9333L24.2227 22.444H20.6667V34C28.2227 32.6587 34 26.0453 34 18.0893C34 9.24 26.8 2 18 2C9.2 2 2 9.24 2 18.0893Z'
            fill='#4A4A4A' />
    </svg>
  );
};

IconSocialMediaFacebook.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaFacebook.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaFacebook;
