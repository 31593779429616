import React, { useEffect, useState } from 'react';
import { string, func, oneOfType, array } from 'prop-types';
import { ABOUT_SELLER_REVIEWS, ABOUT_SELLER_PHOTOS } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import truncate from 'lodash/truncate';
import classNames from 'classnames';
import {
  AvatarLarge,
  Icon,
  NamedLink,
  InlineTextButton,
  Modal,
  ReviewRating,
  ButtonTabNavHorizontal,
} from '../../components';
import { ensureUser, ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import css from './UserCard.module.css';

const BIO_COLLAPSED_LENGTH = 170;

const truncated = s => {
  return truncate(s, {
    length: BIO_COLLAPSED_LENGTH,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};

// class ExpandableBio extends Component {
//   constructor(props) {
//     super(props);
//     this.state = { expand: false };
//   }
//   render() {
//     const { expand } = this.state;
//     const { className, bio } = this.props;
//     const truncatedBio = truncated(bio);

//     const handleShowMoreClick = () => {
//       this.setState({ expand: true });
//     };
//     const showMore = (
//       <InlineTextButton rootClassName={css.showMore} onClick={handleShowMoreClick}>
//         <FormattedMessage id="UserCard.showFullBioLink" />
//       </InlineTextButton>
//     );
//     return (
//       <p className={className}>
//         {expand ? bio : truncatedBio}
//         {bio !== truncatedBio && !expand ? showMore : null}
//       </p>
//     );
//   }
// }

// ExpandableBio.defaultProps = { className: null };

// ExpandableBio.propTypes = {
//   className: string,
//   bio: string.isRequired,
// };

const UserCard = props => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isFollow, setIsFollow] = useState(false);
  const [isShowFollowButton, setIsShowFollowButton] = useState(false);
  const [modalTabType, setModalTabType] = useState(ABOUT_SELLER_REVIEWS);
  const {
    rootClassName,
    className,
    user,
    reviews,
    currentUser,
    onUserFollow,
    onContactUser,
  } = props;
  const userIsCurrentUser = user && user.type === 'currentUser';
  const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const isCurrentUser =
    ensuredUser.id && ensuredCurrentUser.id && ensuredUser.id.uuid === ensuredCurrentUser.id.uuid;
  const publicData = ensuredUser.attributes.profile.publicData
    ? ensuredUser.attributes.profile.publicData
    : {};
  const {
    companyName = '',
    established = '',
    sales = '',
    sellerSince = '',
    typicalResponse = '',
    address = '',
    sellerChecked = false,
  } = Object.keys(publicData).length > 0 && publicData;

  const { displayName, bio } = ensuredUser.attributes.profile;
  const countReviews = reviews ? reviews?.length : 0;
  const reviewRating = reviews?.length
    ? reviews
        .reduce((prev, current) => {
          return prev + current.attributes.rating / reviews.length;
        }, 0)
        .toFixed(1)
    : 0;
  const ratingNumber = parseInt(reviewRating);

  const handleContactUserClick = () => {
    onContactUser(user);
  };

  const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
    // We are just checking the value for now
    console.log('Toggling Modal - scrollingDisabled currently:', componentId, scrollingDisabled);
  };

  const showAboutSellerReviews = () => {
    setModalTabType(ABOUT_SELLER_REVIEWS);
  };

  const showAboutSellerPhotos = () => {
    setModalTabType(ABOUT_SELLER_PHOTOS);
  };

  const aboutSellerModalTabs = [
    {
      text: <h3 className={css.aboutModalTabTitle}>{countReviews} Reviews</h3>,
      selected: modalTabType === ABOUT_SELLER_REVIEWS,
      onClick: showAboutSellerReviews,
    },
    {
      text: <h3 className={css.aboutModalTabTitle}>Photos</h3>,
      selected: modalTabType === ABOUT_SELLER_PHOTOS,
      onClick: showAboutSellerPhotos,
    },
  ];

  useEffect(() => {
    const authorId = user?.id.uuid;
    setIsShowFollowButton(authorId !== currentUser?.id.uuid);
    if (currentUser && user) {
      const currentUserFollowing = currentUser.attributes?.profile?.publicData?.following || [];

      setIsFollow(currentUserFollowing.includes(authorId));
    }
  }, [isFollow, currentUser]);

  const hasBio = !!bio;
  const classes = classNames(rootClassName || css.root, className);
  const linkClasses = classNames(css.links, {
    [css.withBioMissingAbove]: !hasBio,
  });

  const separator = isCurrentUser ? null : <span className={css.linkSeparator}>•</span>;

  const contact = (
    <InlineTextButton
      rootClassName={css.contact}
      onClick={handleContactUserClick}
      enforcePagePreloadFor="SignupPage"
    >
      <FormattedMessage id="UserCard.contactUser" />
    </InlineTextButton>
  );

  const editProfileMobile = (
    <span className={css.editProfileMobile}>
      <span className={css.linkSeparator}>•</span>
      <NamedLink name="ProfileSettingsPage">
        <FormattedMessage id="ListingPage.editProfileLink" />
      </NamedLink>
    </span>
  );

  const editProfileDesktop = isCurrentUser ? (
    <NamedLink className={css.editProfileDesktop} name="ProfileSettingsPage">
      <FormattedMessage id="ListingPage.editProfileLink" />
    </NamedLink>
  ) : null;

  const links = ensuredUser.id ? (
    <p className={linkClasses}>
      <NamedLink className={css.link} name="ProfilePage" params={{ id: ensuredUser.id.uuid }}>
        <FormattedMessage id="UserCard.viewProfileLink" />
      </NamedLink>
      {separator}
      {isCurrentUser ? editProfileMobile : contact}
    </p>
  ) : null;

  const isShowInfoList = established || sellerSince || sales || typicalResponse;

  return (
    <>
      <div className={classes}>
        <div className={css.content}>
          <div className={css.sellerInfo}>
            <AvatarLarge className={css.avatar} user={user} />
            <div className={css.sellerCompany}>
              <div className={css.sellerCompanyTop}>
                {sellerChecked ? <Icon name="check-circle" size={24} /> : <></>}
                {displayName ? <h5 className={css.sellerCompanyTitle}>{displayName}</h5> : <></>}
                {isShowFollowButton ? (
                  <InlineTextButton className={css.sellerFollow} onClick={onUserFollow}>
                    {isFollow ? (
                      <FormattedMessage id="UserCard.unfollow" />
                    ) : (
                      <FormattedMessage id="UserCard.follow" />
                    )}
                  </InlineTextButton>
                ) : (
                  <></>
                )}
              </div>
              {sellerChecked ? (
                <p className={css.sellerVetted}>
                  <FormattedMessage id="UserCard.sellerVetted" />
                </p>
              ) : (
                <></>
              )}
              <div className={css.sellerBottom}>
                <div className={css.sellerRating}>
                  <span className={css.sellerRatingNumber}>{reviewRating}</span>
                  <ReviewRating rating={ratingNumber} />
                </div>
                <InlineTextButton
                  className={css.sellerReviewsLink}
                  onClick={() => setIsOpenModal(true)}
                >
                  {countReviews} Reviews
                </InlineTextButton>
                {address ? (
                  <div className={css.sellerLocated}>
                    <FormattedMessage id="UserCard.located" /> {address}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {isShowInfoList ? (
            <ul className={css.sellerInfoList}>
              {established ? (
                <li className={css.sellerInfoListItem}>
                  <strong>
                    <FormattedMessage id="UserCard.established" />
                  </strong>{' '}
                  in {established}
                </li>
              ) : (
                <></>
              )}
              {sellerSince ? (
                <li className={css.sellerInfoListItem}>
                  <strong>
                    <FormattedMessage id="UserCard.sellerSince" />
                  </strong>{' '}
                  {sellerSince}
                </li>
              ) : (
                <></>
              )}
              {sales ? (
                <li className={css.sellerInfoListItem}>
                  <strong>
                    <FormattedMessage id="UserCard.sales" />
                  </strong>{' '}
                  {sales}
                </li>
              ) : (
                <></>
              )}
              {typicalResponse ? (
                <li className={css.sellerInfoListItem}>
                  <strong>
                    <FormattedMessage id="UserCard.typicalResponse" />
                  </strong>{' '}
                  {typicalResponse}
                </li>
              ) : (
                <></>
              )}
            </ul>
          ) : (
            <></>
          )}
          {bio ?
            <>
              <p className={css.sellerBio}>{bio}</p>
              <NamedLink name="ProfilePage" params={{ id: user?.id?.uuid }}>
                <FormattedMessage id="UserCard.learnMore" />
              </NamedLink>
            </> :
            <></>
          }
        </div>
      </div>
      <Modal
        id="aboutSellerReviews"
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <header className={css.aboutSellerModalTop}>
          <h3 className={css.aboutSellerModalTitle}>About the seller</h3>
          <div className={css.sellerRating}>
            <span className={css.sellerRatingNumber}>{reviewRating}</span>
            <ReviewRating rating={ratingNumber} />
          </div>
        </header>
        <div className={css.aboutSellerModalContent}>
          <ButtonTabNavHorizontal className={css.sellerTabs} tabs={aboutSellerModalTabs} />
          <div className={css.aboutSellerContentWrapper}>
            {modalTabType === ABOUT_SELLER_REVIEWS ? (
              <div className={css.offer}>
                <div className={css.aboutSellerModalReviewsList}>
                  {reviews.length ? (
                    reviews.map((review, index) => (
                      <div className={css.aboutSellerModalReviewsCard} key={index}>
                        <div className={css.aboutSellerReviewsRight}>
                          <div className={css.aboutSellerReviewRating}>
                            <div className={css.sellerRating}>
                              <span className={css.sellerRatingNumber}>{reviewRating}</span>
                              <ReviewRating rating={review.attributes.rating} />
                            </div>
                            <div className={css.aboutSellerReviewText}>
                              {review.attributes.content}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className={css.aboutSellerReviewText}>You don't have any reviews yet</p>
                  )}
                </div>
              </div>
            ) : (
              <div>Coming soon</div>
            )}
          </div>
        </div>
        {/* <footer className={css.aboutSellerModalFooter}>
          <InlineTextButton className={css.aboutSellerModalFooterButton} onClick={() => ({})}>
            Show more
          </InlineTextButton>
        </footer> */}
      </Modal>
    </>
  );
};

UserCard.defaultProps = {
  rootClassName: null,
  className: null,
  user: null,
  currentUser: null,
};

UserCard.propTypes = {
  rootClassName: string,
  className: string,
  reviews: array,
  user: oneOfType([propTypes.user, propTypes.currentUser]),
  currentUser: propTypes.currentUser,
  onContactUser: func.isRequired,
};

export default UserCard;
