import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { requestSellerApprove } from '../../util/api';
import { gtmEvent } from '../../analytics/gtm';

// ================ Action types ================ //

export const CLEAR_SELLER_FORM = 'app/SellerFormPage/CLEAR_SELLER_FORM';

export const UPDATE_PROFILE_REQUEST = 'app/SellerFormPage/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/SellerFormPage/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/SellerFormPage/UPDATE_PROFILE_ERROR';

const initialState = {
  updateProfileInProgress: false,
  updateProfileError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateProfileInProgress: true,
        updateProfileError: null,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updateProfileInProgress: false,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        updateProfileInProgress: false,
        updateProfileError: payload,
      };

    case CLEAR_SELLER_FORM:
      return { ...state, updateProfileError: null, uploadImageError: null };

    default:
      return state;
  }
}

// ================ Action creators ================ //
export const clearUpdatedForm = () => ({
  type: CLEAR_SELLER_FORM,
});

export const updateProfileRequest = params => ({
  type: UPDATE_PROFILE_REQUEST,
});
export const updateProfileSuccess = result => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: result?.data,
});
export const updateProfileError = error => ({
  type: UPDATE_PROFILE_ERROR,
  payload: error,
  error: true,
});

export const updateProfile = actionPayload => {
  const queryParams = {
    expand: true,
    include: ['profileImage'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  };

  return (dispatch, getState, sdk) => {
    dispatch(updateProfileRequest());

    return sdk.currentUser
      .updateProfile(actionPayload, queryParams)
      .then(() => {
        requestSellerApprove().then(() => {
          dispatch(fetchCurrentUser()).then(response => {
            dispatch(updateProfileSuccess(response));

            // Update current user in state.user.currentUser through user.duck.js
            dispatch(currentUserShowSuccess(response));
          });
        });
      })
      .then(() => {
        console.log('fic');
        const state = getState();

        gtmEvent('new_seller_requested', {
          userId: state.user.currentUser.id.uuid,
          requestInfo: actionPayload.privateData.sellerInfo,
        });
      })
      .catch(e => dispatch(updateProfileError(storableError(e))));
  };
};
