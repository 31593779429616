import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.module.css';

const EsIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootMobileIcons, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path id="es" d="M15,30A15,15,0,1,1,30,15,15.017,15.017,0,0,1,15,30ZM15,1.851A13.149,13.149,0,1,0,28.149,15,13.164,13.164,0,0,0,15,1.851ZM9.222,24.037V6.015h4.384V7.558h-2.76V14h2V15.54h-2v6.954h2.76v1.542H9.222Zm9.2.216c-1.678,0-2.679-1.245-2.679-3.058V18.76h1.569v2.516c0,.893.433,1.569,1.055,1.569.839,0,1.164-.568,1.164-1.569a12.688,12.688,0,0,0-1.6-5.52c-.866-1.786-2.057-4.519-2.057-6.386,0-2.084.812-3.572,2.733-3.572,1.488,0,2.435,1.245,2.435,3.085v2.625H19.5V8.938c0-1.082-.325-1.6-.92-1.6-.7,0-1.136.46-1.136,1.434,0,2.111.649,3.518,1.759,5.845A15.6,15.6,0,0,1,21.1,20.79C21.1,23.6,19.505,24.253,18.423,24.253Z" fill="#262626" />
    </svg>
  );
};

const { string } = PropTypes;

EsIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

EsIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default EsIcon;
