import React from 'react';
import { object, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import {
  required,
  composeValidators,
  moneySubUnitAmountAtLeast,
  moneySubUnitAmountAtMost,
} from '../../util/validators';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { FieldCurrencyInput, InlineTextButton, Icon, Button } from '../../components';
import css from './MakeAnOfferForm.module.css';

const MakeAnOfferFormComponent = props => {
  return (
    <div>
      <FinalForm
        {...props}
        formId="makeAnOffer"
        render={fieldRenderProps => {
          const {
            className,
            currencyConfig,
            intl,
            form,
            handleSubmit,
            onChange,
            invalid,
            pristine,
            submitting,
            values,
            maxValue,
          } = fieldRenderProps;

          const submitDisabled = invalid || pristine || submitting;

          const offerLabel = intl.formatMessage({
            id: 'MakeAnOfferForm.offerLabel',
          });
          const offerPlaceholder = intl.formatMessage({
            id: 'MakeAnOfferForm.offerPlaceholder',
          });

          const requiredMessage = intl.formatMessage({
            id: 'MakeAnOfferForm.validateRequired',
          });

          const minValueValidator = moneySubUnitAmountAtLeast(
            intl.formatMessage({
              id: 'MakeAnOfferForm.validateMin',
            }),
            0
          );
          const maxValueValidator = moneySubUnitAmountAtMost(
            intl.formatMessage({
              id: 'MakeAnOfferForm.validateMax',
            }),
            maxValue - 1
          );
          const offerPriceValidator = composeValidators(
            required(requiredMessage),
            minValueValidator,
            maxValueValidator
          );

          return (
            <form
              className={className}
              onSubmit={e => {
                e.preventDefault();
                handleSubmit(e);
              }}
            >
              <p className={css.makeAnOfferFormText}>
                This seller is likely to accept offers of <strong>5 - 15%</strong> off the List
                Price.
              </p>
              <div className={css.makeAnOfferFormGroup}>
                <FieldCurrencyInput
                  id="makeAnOffer.offer"
                  name="offer"
                  label={offerLabel}
                  placeholder={offerPlaceholder}
                  currencyConfig={currencyConfig}
                  validate={offerPriceValidator}
                />
              </div>
              {/*<InlineTextButton className={css.makeAnOfferIncludeMessage} onClick={() => ({})}>*/}
              {/*  <span className={css.makeAnOfferIncludeMessageText}>*/}
              {/*    <FormattedMessage id="MakeAnOfferForm.includeText" />*/}
              {/*  </span>{' '}*/}
              {/*  <Icon name="plus" size={20} />*/}
              {/*</InlineTextButton>*/}
              <Button
                className={css.makeAnOfferFormSubmitButton}
                type="submit"
                disabled={submitDisabled}
              >
                <FormattedMessage id="MakeAnOfferForm.submitTextButton" />
              </Button>
            </form>
          );
        }}
      />
    </div>
  );
};

MakeAnOfferFormComponent.defaultProps = {
  className: null,
  currencyConfig: {},
};

MakeAnOfferFormComponent.propTypes = {
  className: string,
  currencyConfig: object.isRequired,
  intl: intlShape.isRequired,
};

const MakeAnOfferForm = compose(injectIntl)(MakeAnOfferFormComponent);

MakeAnOfferForm.displayName = 'MakeAnOfferForm';

export default MakeAnOfferForm;
