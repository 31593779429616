export const convertUsers = (artists, images) => {
  return artists.map(artist => {
    const imageId =
      artist.relationships.profileImage.data && artist.relationships.profileImage.data.id.uuid;
    const image = images.find(({ id }) => id.uuid === imageId);

    return {
      ...artist,
      imageUrl: image ? image.attributes.variants['square-small2x'].url : '',
      name: `${artist.attributes.profile.firstName} ${artist.attributes.profile.lastName}`,
      link: `/artists/${artist.id.uuid}`,
      bio: artist.attributes.profile.bio,
      image,
    };
  });
};

export const createArtistsSchema = (artists) => {  
  const MAX_DESCRIPTION_LENGTH = 200;

  return {
    '@context': 'http://schema.org',
    '@type': 'ItemList',
    name: 'Artists',
    description: 'Encore Society Artists',
    itemListOrder: 'http://schema.org/ItemListOrderAscending',
    itemListElement: artists.map((a, i) => ({
      '@type': 'ListItem',
      position: i,
      url: a.link,
      identifier: a.id.uuid,
      image: a.image?.attributes?.variants['square-small2x'].url,
      name: a.name,
      description: a.bio
      ? `${a.bio.slice(0, MAX_DESCRIPTION_LENGTH)}${a.bio.length > MAX_DESCRIPTION_LENGTH ? '...' : ''}`
      : '',
    }))
  };
};
