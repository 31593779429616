import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import Button from '../Button/Button';
import css from './NewsletterConsent.module.css';
import { newsletterSubscribe, newsletterUnsubscribe, newsletterIsSubscribed } from '../../util/api';

const NewsletterConsent = props => {
  const { isSeller, isTrader, email } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const update = async () => {
    if (!email) {
      setIsError(true);
      return;
    }
    setIsLoading(true);

    try {
      const res = isChecked
        ? await newsletterSubscribe({
            email,
            isSeller,
            isTrader,
          })
        : await newsletterUnsubscribe({
            email,
            isSeller,
            isTrader,
          });

      if (res.status === 200) {
        setIsSubscribed(isChecked);
        setIsError(false);
      }
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await newsletterIsSubscribed({
          email,
          isSeller,
          isTrader,
        });

        if (res.status === 200) {
          setIsSubscribed(!!res.data.length);
          setIsError(false);
        }
      } catch {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    setIsChecked(isSubscribed);
  }, [isSubscribed]);

  const error = isError ? (
    <div className={css.error}>
      {email ? (
        <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
      ) : (
        <FormattedMessage id="NewsletterConsent.verifyEmail" />
      )}
    </div>
  ) : null;

  return (
    <div className={css.root}>
      <h1 className={css.heading}>
        <FormattedMessage id="NewsletterConsent.heading" />
      </h1>
      <div className={css.row}>{error}</div>
      <div className={css.row}>
        <input
          className={css.checkbox}
          id="newsletter"
          type="checkbox"
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        />
        <label htmlFor="newsletter">
          {isTrader ? (
            <FormattedMessage id="NewsletterConsent.traderSubscribe" />
          ) : isSeller ? (
            <FormattedMessage id="NewsletterConsent.sellerSubscribe" />
          ) : (
            <FormattedMessage id="NewsletterConsent.subscribe" />
          )}
        </label>
        <Button className={css.button} onClick={update} inProgress={isLoading}>
          <FormattedMessage id="NewsletterConsent.update" />
        </Button>
      </div>
    </div>
  );
};

NewsletterConsent.defaultProps = {
  isSeller: false,
  isTrader: false,
  email: null,
};

const { bool, string } = PropTypes;

NewsletterConsent.propTypes = {
  isSeller: bool,
  isTrader: bool,
  email: string,
};

export default NewsletterConsent;
