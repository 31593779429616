import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconReviewStar.module.css';

const IconReviewStar = props => {
  const { className, rootClassName, isFilled } = props;
  const filledOrDefault = isFilled ? css.filled : css.root;
  const classes = classNames(rootClassName || filledOrDefault, className);

  return (
    <svg
      className={classes}
      width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.6579 4.01849C13.5856 1.69387 10.2818 1.69387 9.20952 4.01849L7.82893 7.01159L4.5557 7.39968C2.01352 7.7011 0.99257 10.8433 2.87207 12.5814L5.29206 14.8193L4.64967 18.0523C4.15076 20.5632 6.82364 22.5051 9.05747 21.2547L11.9337 19.6447L14.8099 21.2547C17.0437 22.5051 19.7166 20.5632 19.2177 18.0523L18.5753 14.8193L20.9953 12.5814C22.8748 10.8433 21.8539 7.7011 19.3117 7.39968L16.0384 7.01159L14.6579 4.01849Z" />
    </svg>
  );
};

IconReviewStar.defaultProps = { className: null, rootClassName: null, isFilled: false };

const { bool, string } = PropTypes;

IconReviewStar.propTypes = {
  className: string,
  rootClassName: string,
  isFilled: bool,
};

export default IconReviewStar;
