import React, {useState} from 'react';

import css from './Accordion.module.css';
import { MockCategories } from './Categories';
import { IconArrowHead } from '../../index';

const Accordion = ({categoryActive, setCategory}) => {
  const [isAnyCategoryShown, setIsAnyCategoryShown] = useState(null);
  const categoriesList = Object.keys(MockCategories);

  return (
    <div className={css.accordion}>
      {categoriesList.map((category) => (
        <div className={css.accordionItem} key={category}>
          <header className={css.accordionItemHeader}>
            <a
              className={css.link}
              href={`/catalog/${category}`}
            >
              <div className={css.accordionItemHeaderContent}>
                <img className={css.categoryIcon} src={MockCategories[category].img} />
                <span className={css.categoryName}>
                  {MockCategories[category].label}
                </span>
              </div>
            </a>
            <div
              className={css.arrowBox}
              onClick={() => {
                if (isAnyCategoryShown !== null && categoryActive === category) {
                  setIsAnyCategoryShown(false);
                } else {
                  setIsAnyCategoryShown(true);
                  setCategory({ key: category })
                }
              }}
            >
              <IconArrowHead direction={isAnyCategoryShown && categoryActive === category ? "down" : "right"} rootClassName={css.arrow} />
            </div>
          </header>
          <div className={css.accordionItemBox} style={isAnyCategoryShown && categoryActive === category ? {maxHeight: '700px'} : null}>
            {MockCategories[category].subcategories.map((subcategory) => (
              <div className={css.accordionContent} key={subcategory.label}>
                <a
                  className={css.link}
                  href={`/catalog/${
                    subcategory.key === "console_tables" &&
                    category === "tables"
                      ? "casegoods_and_storage"
                      : category
                  }/${subcategory.key}`}
                >
                  <img className={css.categoryIcon} src={subcategory.img} />
                  <span className={css.categoryName}>{subcategory.label}</span>
                </a>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export { Accordion };
