import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess } from '../../ducks/user.duck';

// ================ Action types ================ //

export const CLEAR_UPDATED_FORM = 'app/ProfileSettingsPage/CLEAR_UPDATED_FORM';

export const UPLOAD_IMAGE_REQUEST = 'app/ProfileSettingsPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/ProfileSettingsPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/ProfileSettingsPage/UPLOAD_IMAGE_ERROR';

export const UPLOAD_BACKGROUND_IMAGE_REQUEST = 'app/ProfileSettingsPage/UPLOAD_BACKGROUND_IMAGE_REQUEST';
export const UPLOAD_BACKGROUND_IMAGE_SUCCESS = 'app/ProfileSettingsPage/UPLOAD_BACKGROUND_IMAGE_SUCCESS';
export const UPLOAD_BACKGROUND_IMAGE_ERROR = 'app/ProfileSettingsPage/UPLOAD_BACKGROUND_IMAGE_ERROR';

export const UPLOAD_PORTFOLIO_IMAGE_REQUEST = 'app/ProfileSettingsPage/UPLOAD_PORTFOLIO_IMAGE_REQUEST';
export const UPLOAD_PORTFOLIO_IMAGE_SUCCESS = 'app/ProfileSettingsPage/UPLOAD_PORTFOLIO_IMAGE_SUCCESS';
export const UPLOAD_PORTFOLIO_IMAGE_ERROR = 'app/ProfileSettingsPage/UPLOAD_PORTFOLIO_IMAGE_ERROR';
export const DELETE_PORTFOLIO_IMAGE = 'app/ProfileSettingsPage/DELETE_PORTFOLIO_IMAGE';
export const SET_PORTFOLIO_IMAGE = 'app/ProfileSettingsPage/SET_PORTFOLIO_IMAGE';

export const UPDATE_PROFILE_REQUEST = 'app/ProfileSettingsPage/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/ProfileSettingsPage/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/ProfileSettingsPage/UPDATE_PROFILE_ERROR';

// ================ Reducer ================ //

const initialState = {
  image: null,
  backgroundImage: null,
  portfolio: null,
  uploadImageError: null,
  uploadInProgress: false,
  uploadBackgroundImageError: null,
  uploadBackgroundInProgress: false,
  uploadPortfolioImageError: null,
  uploadPortfolioInProgress: false,
  updateInProgress: false,
  updateProfileError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPLOAD_IMAGE_REQUEST:
      // payload.params: { id: 'tempId', file }
      return {
        ...state, image: { ...payload.params }, uploadInProgress: true, uploadImageError: null,
      };
    case UPLOAD_IMAGE_SUCCESS: {
      // payload: { id: 'tempId', uploadedImage }
      const { id, uploadedImage } = payload;
      const { file } = state.image || {};
      const image = { id, imageId: uploadedImage.id, file, uploadedImage };
      return { ...state, image, uploadInProgress: false };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      return { ...state, image: null, uploadInProgress: false, uploadImageError: payload.error };
    }

    case UPLOAD_PORTFOLIO_IMAGE_REQUEST:
      return {
        ...state, uploadPortfolioInProgress: true, uploadPortfolioImageError: null,
      };
    case UPLOAD_PORTFOLIO_IMAGE_SUCCESS: {
      // payload: { id: 'tempId', uploadedPortfolioImage }
      return {
        ...state, portfolio: [...state.portfolio, payload], uploadPortfolioInProgress: false,
      };
    }
    case UPLOAD_PORTFOLIO_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      return {
        ...state,
        portfolio: [...state.portfolio],
        uploadPortfolioInProgress: false,
        uploadPortfolioImageError: payload.error,
      };
    }
    case DELETE_PORTFOLIO_IMAGE: {
      const newPortfolio = state.portfolio.filter(el => el.id.uuid !== payload);
      return { ...state, portfolio: newPortfolio };
    }
    case SET_PORTFOLIO_IMAGE: {
      return { ...state, portfolio: payload };
    }

    case UPLOAD_BACKGROUND_IMAGE_REQUEST:
      // payload.params: { id: 'tempId', file }
      return {
        ...state,
        backgroundImage: { ...payload.params },
        uploadBackgroundInProgress: true,
        uploadBackgroundImageError: null,
      };
    case UPLOAD_BACKGROUND_IMAGE_SUCCESS: {
      // payload: { id: 'tempId', uploadedImage }
      const { id, uploadedImage } = payload;
      const { file } = state.backgroundImage || {};
      const backgroundImage = { id, imageId: uploadedImage.id, file, uploadedImage };
      return { ...state, backgroundImage, uploadBackgroundInProgress: false };
    }
    case UPLOAD_BACKGROUND_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      return {
        ...state,
        backgroundImage: null,
        uploadBackgroundInProgress: false,
        uploadBackgroundImageError: payload.error,
      };
    }

    case UPDATE_PROFILE_REQUEST:
      return {
        ...state, updateInProgress: true, updateProfileError: null,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state, image: null, backgroundImage: null, updateInProgress: false,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        image: null,
        backgroundImage: null,
        updateInProgress: false,
        updateProfileError: payload,
      };

    case CLEAR_UPDATED_FORM:
      return { ...state, updateProfileError: null, uploadImageError: null };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const clearUpdatedForm = () => ({
  type: CLEAR_UPDATED_FORM,
});

// SDK method: images.upload
export const uploadImageRequest = params => ({ type: UPLOAD_IMAGE_REQUEST, payload: { params } });
export const uploadImageSuccess = result => ({ type: UPLOAD_IMAGE_SUCCESS, payload: result.data });
export const uploadImageError = error => ({
  type: UPLOAD_IMAGE_ERROR, payload: error, error: true,
});

export const uploadPortfolioImageRequest = params => ({
  type: UPLOAD_PORTFOLIO_IMAGE_REQUEST, payload: { params },
});
export const uploadPortfolioImageSuccess = payload => ({
  type: UPLOAD_PORTFOLIO_IMAGE_SUCCESS, payload,
});
export const uploadPortfolioImageError = error => ({
  type: UPLOAD_PORTFOLIO_IMAGE_ERROR, payload: error, error: true,
});
export const deletePortfolioImage = params => ({ type: DELETE_PORTFOLIO_IMAGE, payload: params });
export const setPortfolioImage = payload => ({ type: SET_PORTFOLIO_IMAGE, payload })

export const uploadBackgroundImageRequest = params => ({
  type: UPLOAD_BACKGROUND_IMAGE_REQUEST, payload: { params },
});
export const uploadBackgroundImageSuccess = result => ({
  type: UPLOAD_BACKGROUND_IMAGE_SUCCESS, payload: result.data,
});
export const uploadBackgroundImageError = error => ({
  type: UPLOAD_BACKGROUND_IMAGE_ERROR, payload: error, error: true,
});

// SDK method: sdk.currentUser.updateProfile
export const updateProfileRequest = params => ({
  type: UPDATE_PROFILE_REQUEST, payload: { params },
});
export const updateProfileSuccess = result => ({
  type: UPDATE_PROFILE_SUCCESS, payload: result?.data,
});
export const updateProfileError = error => ({
  type: UPDATE_PROFILE_ERROR, payload: error, error: true,
});

// ================ Thunk ================ //

// Images return imageId which we need to map with previously generated temporary id
export function uploadImage(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    dispatch(uploadImageRequest(actionPayload));

    const bodyParams = {
      image: actionPayload.file,
    };
    const queryParams = {
      expand: true, 'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.images
      .upload(bodyParams, queryParams)
      .then(resp => {
        const uploadedImage = resp.data.data;
        dispatch(uploadImageSuccess({ data: { id, uploadedImage } }));
      })
      .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}

export function uploadBackgroundImage(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    dispatch(uploadBackgroundImageRequest(actionPayload));
    const bodyParams = {
      image: actionPayload.file,
    };
    const queryParams = {
      expand: true, 'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.images
      .upload(bodyParams, queryParams)
      .then(resp => {
        const uploadedImage = resp.data.data;
        dispatch(uploadBackgroundImageSuccess({ data: { id, uploadedImage } }));
      })
      .catch(e => dispatch(uploadBackgroundImageError({ id, error: storableError(e) })));
  };
}

export function uploadPortfolioImage(actionPayload) {
  return (dispatch, getState, sdk) => {
    dispatch(uploadPortfolioImageRequest(actionPayload));

    const bodyParams = {
      image: actionPayload.file,
    };
    const queryParams = {
      expand: true, 'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.images
      .upload(bodyParams, queryParams)
      .then(resp => {
        const uploadedImage = {
          id: { uuid: resp.data.data.id.uuid },
          attributes: { variants: { 'square-small2x': resp.data.data.attributes?.variants['square-small2x'] } },
          type: 'image',
        };
        dispatch(uploadPortfolioImageSuccess(uploadedImage));
      });
    // return dispatch(uploadPortfolioImageSuccess())
  };
}

export const updateProfile = actionPayload => {
  return (dispatch, getState, sdk) => {
    dispatch(updateProfileRequest());

    const queryParams = {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.currentUser
      .updateProfile(actionPayload, queryParams)
      .then(response => {
        if (response) {
          dispatch(updateProfileSuccess(response));

          const entities = denormalisedResponseEntities(response);
          if (entities.length !== 1) {
            throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
          }
          const currentUser = entities[0];

          // Update current user in state.user.currentUser through user.duck.js
          dispatch(currentUserShowSuccess(currentUser));
        }
      })
      .catch(e => dispatch(updateProfileError(storableError(e))));
  };
};
