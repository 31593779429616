/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';

import { NamedLink, NotificationBadge } from '../../../components';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      <div className={css.content}>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('PurchaseHistoryPage'))}
          name="InboxPage"
          params={{ tab: 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.purchaseHistoryLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('CartPage'))}
          name="CartPage"
        >
          <FormattedMessage id="TopbarMobileMenu.cartLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileFollowingsPage'))}
          name="ProfileFollowingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.followingsLink" />
        </NamedLink>
        <NamedLink className={css.navigationLink} name="Wishlist" params={{ tab: 'wishlist' }}>
            <FormattedMessage id="TopbarDesktop.wishlist" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AddressManagementPage'))}
          name="AddressManagementPage"
        >
          <FormattedMessage id="TopbarMobileMenu.addressManagementLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ArtistsPage'))}
          name="ArtistsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.artistsLink" />
        </NamedLink>
        <hr style={{width: '100%', backgroundColor: 'rgb(178, 178, 178)', height: '1px', border: 0, marginBottom: 0}} />
        <span className={classNames(css.navigationLink, css.logoutButton)} style={{fontSize: '18px'}} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </span>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
