import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';

import config from '../../config';
import routeConfiguration from '../../routing/routeConfiguration';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../util/search';
import { withViewport } from '../../util/contextHelpers';
import { parse } from '../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import {
  Avatar,
  Button,
  LimitedAccessBanner,
  Logo,
  ModalMissingInformation,
  NamedLink,
} from '../../components';

import MenuIcon from './MenuIcon';
import TopbarSearchForm from './TopbarSearchForm/TopbarSearchForm';
import TopbarMobileMenu from './TopbarMobileMenu/TopbarMobileMenu';
import TopbarDesktop from './TopbarDesktop/TopbarDesktop';

import css from './Topbar.module.css';

import NavItem from './NavbarComponent';
import BasketIcon from './BasketIcon';
import EsIcon from './EsIcon';
import ProfileIcon from './ProfileIcon';
import Categories from './Categories/Categories';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const GenericError = props => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

const { bool } = PropTypes;

GenericError.propTypes = {
  show: bool.isRequired,
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileMenuShow: false,
      isMobileUserShow: false,
    };

    this.handleMobileMenu = this.handleMobileMenu.bind(this);
    this.handleMobileUser = this.handleMobileUser.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleMobileMenu() {
    this.setState({ isMobileMenuShow: !this.state.isMobileMenuShow, isMobileUserShow: false });
  }

  handleMobileUser() {
    this.setState({ isMobileUserShow: !this.state.isMobileUserShow });
  }

  handleSubmit(values) {
    const { currentSearchParams } = this.props;
    const { history } = this.props;

    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords: values?.keywords };
      }
      // topbar search defaults to 'location' search
      const { search, selectedPlace } = values?.location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = isOriginInUse(config) ? { origin } : {};

      return {
        ...originMaybe,
        address: search,
        bounds,
      };
    };
    const searchParams = {
      ...currentSearchParams,
      ...topbarSearchParams(),
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  handleLogout() {
    const { onLogout, history } = this.props;
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration());

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (config.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }
    });
  }

  render() {
    const {
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      authScopes,
      currentUser,
      currentUserHasListings,
      currentUserHasOrders,
      currentPage,
      notificationCount,
      viewport,
      intl,
      location,
      onManageDisableScrolling,
      onResendVerificationEmail,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      showGenericError,

      onResendResetPasswordEmail,
      sendResetPasswordEmailInProgress,
      sendResetPasswordEmailError,
    } = this.props;
    const { mobilemenu, mobilesearch, keywords, address, origin, bounds } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

    const topbarSearcInitialValues = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords };
      }

      // Only render current search if full place object is available in the URL params
      const locationFieldsPresent = isOriginInUse(config)
        ? address && origin && bounds
        : address && bounds;
      return {
        location: locationFieldsPresent
          ? {
              search: address,
              selectedPlace: { address, origin, bounds },
            }
          : null,
      };
    };
    const initialSearchFormValues = topbarSearcInitialValues();

    const classes = classNames(rootClassName || css.root, className);
    const burgerIconClasses = classNames(
      css.burgerIcon,
      this.state.isMobileMenuShow ? css.active : ''
    );

    const mobileMenuLinks = isAuthenticated ? (
      <span onClick={() => this.handleMobileUser()}>
        <Avatar user={currentUser} disableProfileLink />
      </span>
    ) : (
      <>
        <NamedLink
          className={css.homeMobileIcons}
          name="SignupPage"
          title={intl.formatMessage({ id: 'TopbarDesktop.signup' })}
        >
          <EsIcon />
        </NamedLink>
        <NamedLink
          className={css.homeMobileIcons}
          name="LoginPage"
          title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
        >
          <ProfileIcon />
        </NamedLink>
      </>
    );

    return (
      <>
        <div className={classes}>
          <LimitedAccessBanner
            isAuthenticated={isAuthenticated}
            authScopes={authScopes}
            currentUser={currentUser}
            onLogout={this.handleLogout}
            currentPage={currentPage}
          />
          <div className={classNames(mobileRootClassName || css.container, mobileClassName)}>
            <div className={css.headerMobileTop}>
              <Button
                rootClassName={css.menu}
                onClick={() => this.handleMobileMenu()}
                title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
              >
                {/* <MenuIcon className={css.menuIcon} /> */}
                <div className={burgerIconClasses}></div>
                {notificationDot}
              </Button>
              <NamedLink
                className={css.home}
                name="LandingPage"
                title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
              >
                <Logo className={css.logo} format="mobile" />
              </NamedLink>
              <div className={css.iconsMobile}>
                <a className={css.homeMobileIcons} href="/catalog">
                  <BasketIcon />
                </a>
                {mobileMenuLinks}
              </div>
            </div>
            <div style={{ width: '100%' }}>
              <TopbarSearchForm
                className={css.searchLink}
                onSubmit={this.handleSubmit}
                initialValues={initialSearchFormValues}
                appConfig={config}
                isMobile
              />
            </div>
          </div>
          <div className={css.desktop}>
            <TopbarDesktop
              className={desktopClassName}
              currentUserHasListings={currentUserHasListings}
              currentUser={currentUser}
              currentPage={currentPage}
              initialSearchFormValues={initialSearchFormValues}
              intl={intl}
              isAuthenticated={isAuthenticated}
              notificationCount={notificationCount}
              onLogout={this.handleLogout}
              onSearchSubmit={this.handleSubmit}
              appConfig={config}
            />
          </div>
          <div
            className={css.slidingMenu}
            style={
              this.state.isMobileMenuShow
                ? {
                    opacity: 1,
                    transform: 'none',
                  }
                : { opacity: 0, transform: 'translateY(-100%)' }
            }
          >
            <div className={css.slidingBox}>
              <Categories />
            </div>
          </div>
          {this.state.isMobileUserShow ? (
            <TopbarMobileMenu
              currentUserHasListings={currentUserHasListings}
              currentUser={currentUser}
              onLogout={this.handleLogout}
              notificationCount={notificationCount}
              currentPage={currentPage}
            />
          ) : null}
          <ModalMissingInformation
            id="MissingInformationReminder"
            containerClassName={css.missingInformationModal}
            currentUser={currentUser}
            currentUserHasListings={currentUserHasListings}
            currentUserHasOrders={currentUserHasOrders}
            location={location}
            onManageDisableScrolling={onManageDisableScrolling}
            onResendVerificationEmail={onResendVerificationEmail}
            sendVerificationEmailInProgress={sendVerificationEmailInProgress}
            sendVerificationEmailError={sendVerificationEmailError}
            onResendResetPasswordEmail={onResendResetPasswordEmail}
            sendResetPasswordEmailInProgress={sendResetPasswordEmailInProgress}
            sendResetPasswordEmailError={sendResetPasswordEmailError}
          />

          <GenericError show={showGenericError} />
        </div>
        <NavItem mobilemenu={isMobileLayout} />
      </>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
};

const { array, func, number, shape, string } = PropTypes;

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,

  onResendResetPasswordEmail: func.isRequired,
  sendResetPasswordEmailInProgress: bool.isRequired,
  sendResetPasswordEmailError: propTypes.error,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Topbar = compose(withViewport, injectIntl)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
