import React, { useState } from 'react';

import css from './Categories.module.css';
import { Accordion } from './Accordion';

export const MockCategories = {
  seating: {
    id: "seatingsubcategory",
    type: "SelectMultipleFilter",
    group: "primary",
    label: "Seating",
    key: "seating",
    img: `/static/images/categories/seating/accent-chair.png`,
    queryParamNames: ["pub_subcategory"],
    subcategories: [
      {
        key: "accent_chair",
        label: "Accent Chairs",
        img: `/static/images/categories/seating/accent-chair.png`,
      },
      {
        key: "benches",
        label: "Benches",
        img: `/static/images/categories/seating/benches.png`,
      },
      {
        key: "barstools",
        label: "Barstools",
        img: `/static/images/categories/seating/barstools.png`,
      },
      {
        key: "chair_set",
        label: "Chair Sets",
        img: `/static/images/categories/seating/chair-sets.png`,
      },
      {
        key: "dining_chair",
        label: "Dining Chairs",
        img: `/static/images/categories/seating/dining-chairs.png`,
      },
      {
        key: "ottomans_footstools",
        label: "Ottomans & Footstools",
        img: `/static/images/categories/seating/ottomans-and-footstools.png`,
      },
    ],
  },
  tables: {
    id: "tablesubcategory",
    type: "SelectMultipleFilter",
    group: "primary",
    label: "Tables",
    key: "tables",
    img: `/static/images/categories/tables/accent-table.png`,
    queryParamNames: ["pub_subcategory"],
    subcategories: [
      {
        key: "accent_tables",
        label: "Accent Tables",
        img: `/static/images/categories/tables/accent-table.png`,
      },
      {
        key: "coffee_table",
        label: "Coffee Table",
        img: `/static/images/categories/tables/coffee-table.png`,
      },
      {
        key: "desks",
        label: "Desks",
        img: `/static/images/categories/tables/desk.png`,
      },
      {
        key: "dining_table",
        label: "Dining Table",
        img: `/static/images/categories/tables/dining-table.png`,
      },
      {
        key: "console_tables",
        label: "Console Tables",
        img: `/static/images/categories/tables/console-table.png`,
      },
    ],
  },
  casegoods_and_storage: {
    id: "casegoodsubcategory",
    type: "SelectMultipleFilter",
    group: "primary",
    label: "Storage and Organization",
    key: "casegoods_and_storage",
    img: `/static/images/categories/storage-and-organization/armoires-wardrobes.png`,
    queryParamNames: ["pub_subcategory"],
    subcategories: [
      {
        key: "armoires_wardrobes",
        label: "Armoires & Wardrobes",
        img: `/static/images/categories/storage-and-organization/armoires-wardrobes.png`,
      },
      {
        key: "bookcases",
        label: "Bookcases",
        img: `/static/images/categories/storage-and-organization/bookcases.png`,
      },
      {
        key: "china_display_cabinets",
        label: "China & Display Cabinets",
        img: `/static/images/categories/storage-and-organization/china-and-display-cabinets.png`,
      },
      {
        key: "credenzas",
        label: "Credenzas",
        img: `/static/images/categories/storage-and-organization/сredenza.png`,
      },
      {
        key: "dresser_chest_drawers",
        label: "Dressers & Chests of Drawers",
        img: `/static/images/categories/storage-and-organization/dressers-and-chest-of-drawers.png`,
      },
      {
        key: "etageres",
        label: "Etageres",
        img: `/static/images/categories/storage-and-organization/etageres.png`,
      },
      {
        key: "vanities",
        label: "Vanities",
        img: `/static/images/categories/storage-and-organization/vanities.png`,
      },
      {
        key: "nightstands",
        label: "Nightstands",
        img: `/static/images/categories/storage-and-organization/nightstands.png`,
      },
    ],
  },
  beds: {
    id: "bedsubcategory",
    type: "SelectMultipleFilter",
    group: "primary",
    label: "Beds",
    key: "beds",
    img: `/static/images/categories/beds/bed-frames.png`,
    queryParamNames: ["pub_subcategory"],
    subcategories: [
      {
        key: "bed_frames",
        label: "Bed Frames",
        img: `/static/images/categories/beds/bed-frames.png`,
      },
      {
        key: "canopy_beds",
        label: "Canopy Beds",
        img: `/static/images/categories/beds/canopy-bed.png`,
      },
      {
        key: "headboards",
        label: "Headboards",
        img: `/static/images/categories/beds/headboards.png`,
      },
    ],
  },
};

const DEFAULT_CATEGORY = "seating";

export const MockMainCategories = [
  {
    id: "seatingsubcategory",
    key: "seating",
    label: "Seating",
    img: `/static/images/categories/seating/accent-chair.png`,
  },
  {
    id: "tablesubcategory",
    key: "tables",
    label: "Tables",
    img: `/static/images/categories/tables/accent-table.png`,
  },
  {
    id: "casegoodsubcategory",
    key: "casegoods_and_storage",
    label: "Storage and Organization",
    img: `/static/images/categories/storage-and-organization/armoires-wardrobes.png`,
  },
  {
    id: "bedsubcategory",
    key: "beds",
    label: "Beds",
    img: `/static/images/categories/beds/bed-frames.png`,
  },
];

const Categories = (props) => {
  const { isTitle = false } = props;
  const [categoryActive, setCategoryActive] =
  useState(DEFAULT_CATEGORY);
  const [selectedSubcategories, setSelectedSubcategories] = useState(MockCategories[categoryActive]?.subcategories);

  const onMouseEnterHandler = (category) => {
    setCategoryActive(category.key);
    setSelectedSubcategories(() => MockCategories[category.key].subcategories);
  };

  return (
    <div className={css.categories}>
      <div className={css.container}>
        {isTitle && (
          <span className={css.categoriesTitle}>Shop by Category</span>
        )}
        <div className={css.categoriesList}>
          {MockMainCategories.map((category) => (
            <div
              className={css.categoryTab}
              key={category.id}
              onMouseEnter={() => onMouseEnterHandler(category)}
              style={categoryActive === category.key ? {borderColor: 'rgb(38, 38, 38)'} : null}
            >
              <div className={css.categoryNameWrapper}>
                <span
                  className={css.categoryName}
                  style={categoryActive === category.key ? {color: 'rgb(38, 38, 38)'} : null}
                >
                  <a href={`/catalog/${category.key}`}>{category.label}</a>
                </span>
              </div>
            </div>
          ))}
        </div>
        <Accordion categoryActive={categoryActive} setCategory={onMouseEnterHandler} />
        {Object.entries(MockCategories).map(([categoryKey, category]) => {
          return (
            <div
              className={css.categoryCardContainer}
              key={categoryKey}
              style={categoryActive !== categoryKey ? {display: 'none'} : null}
            >
              {category.subcategories.map((subcategory, index) => (
                <a
                  className={css.linkSubcategory}
                  key={index}
                  href={`/catalog/${
                    subcategory.key === "console_tables" &&
                    categoryActive === "tables"
                      ? "casegoods_and_storage"
                      : categoryActive
                  }/${subcategory.key}`}
                >
                  <div className={css.categoryCard}>
                    <img className={css.categoryCardImg} src={subcategory.img} />
                    <div className={css.categoryCardTitle}>
                      {subcategory.label}
                    </div>
                  </div>
                </a>
              ))}
            </div>
          );
        })}
        <a className={css.buttonLink} href="/catalog">
          <div>SHOP ALL</div>
        </a>
      </div>
    </div>
  );
};

export default Categories;
