import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { txIsCanceled, txIsReceived, txIsCompleted } from '../../util/transaction';
import { propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemTotalPrice = props => {
  const { transaction, isProvider, intl } = props;

  let providerTotalMessageId = 'OrderBreakdown.providerTotalDefault';
  if (txIsReceived(transaction) || txIsCompleted(transaction)) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalReceived';
  } else if (txIsCanceled(transaction)) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalCanceled';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="OrderBreakdown.total" />
  );
  // let pri = extimatedP ? extimatedP : 0
  // transaction.attributes.uship = pri;
  // const ushipP = transaction.attributes.uship ? transaction.attributes.uship :0
  let totalPrice = null
  if(isProvider)
      {
        transaction.attributes.payoutTotal.amount =  transaction.attributes.payoutTotal.amount
        totalPrice = transaction.attributes.payoutTotal
      }else{
        transaction.attributes.payinTotal.amount =transaction.attributes.payinTotal.amount
        totalPrice = transaction.attributes.payinTotal
      }

  const formattedTotalPrice = formatMoney(intl, totalPrice);

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
      </div>
    </>
  );
};

LineItemTotalPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTotalPrice;
