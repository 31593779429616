export const unitedStates = [
	{code: "AL"},
	{code: "AK"},
	{code: "AZ"},
	{code: "AR"},
	{code: "CA"},
	{code: "CO"},
	{code: "CT"},
	{code: "DE"},
	{code: "DC"},
	{code: "FL"},
	{code: "GA"},
	{code: "HI"},
	{code: "ID"},
	{code: "IL"},
	{code: "IN"},
	{code: "IA"},
	{code: "KS"},
	{code: "KY"},
	{code: "LA"},
	{code: "ME"},
	{code: "MD"},
	{code: "MA"},
	{code: "MI"},
	{code: "MN"},
	{code: "MS"},
	{code: "MO"},
	{code: "MT"},
	{code: "NE"},
	{code: "NV"},
	{code: "NH"},
	{code: "NJ"},
	{code: "NM"},
	{code: "NY"},
	{code: "NC"},
	{code: "ND"},
	{code: "OH"},
	{code: "OK"},
	{code: "OR"},
	{code: "PA"},
	{code: "RI"},
	{code: "SC"},
	{code: "SD"},
	{code: "TN"},
	{code: "TX"},
	{code: "UT"},
	{code: "VT"},
	{code: "VA"},
	{code: "WA"},
	{code: "WV"},
	{code: "WI"},
	{code: "WY"}
]