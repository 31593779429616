import React from "react";
import { array, string, func } from "prop-types";
import BreadcrumbsItem from "./BreadcrumbsItem";
import css from "./Breadcrumbs.module.css";


const Breadcrumbs = (props) => {
  const { breads, onClick } = props;

  const handleBreadClick = (bread) => {
    if (!bread.clickable) return;
    onClick(bread);
  };

  const SeparatedItem = ({ breads, index }) => {
    if (index < breads.length - 1)
      return (
        <>
          <BreadcrumbsItem
            {...breads[index]}
            onClick={handleBreadClick.bind(null, breads[index])}
          />
          <div className={css.breadCrumbsSeparator}></div>
        </>
      );

    return (
      <BreadcrumbsItem
        {...breads[index]}
        onClick={handleBreadClick.bind(null, breads[index])}
      />
    );
  };

  return (
    <div className={css.breadCrumbs}>
      {breads.map((bread, index) => (
        <SeparatedItem key={index} breads={breads} index={index} />
      ))}
    </div>
  )
}

Breadcrumbs.defaulProps = {
  breads: [],
  className: null,
  onClick: () => null
}

Breadcrumbs.propTypes = {
  className: string,
  breads: array,
  onClick: func.isRequired
}

export default Breadcrumbs;
