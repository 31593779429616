import React from 'react';

import css from './TopbarSearchForm.module.css';

const IconSearchDesktop = () => (
  <svg
    viewBox="0 0 26 26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0313 2.4375C19.7429 2.4375 23.5625 6.25706 23.5625 10.9688C23.5625 15.6804 19.7429 19.5 15.0313 19.5C13.0406 19.5 11.2101 18.8183 9.75894 17.6768L4.17056 23.2651C4.07624 23.3594 3.96427 23.4343 3.84103 23.4853C3.71779 23.5364 3.58571 23.5626 3.45231 23.5626C3.31892 23.5626 3.18684 23.5364 3.0636 23.4853C2.94036 23.4343 2.82839 23.3594 2.73406 23.2651C2.63974 23.1708 2.56492 23.0588 2.51387 22.9356C2.46283 22.8124 2.43655 22.6803 2.43655 22.5469C2.43655 22.4135 2.46283 22.2814 2.51387 22.1582C2.56492 22.0349 2.63974 21.9229 2.73406 21.8286L8.32325 16.2403C7.13947 14.7384 6.49707 12.8811 6.5 10.9688C6.5 6.25706 10.3196 2.4375 15.0313 2.4375ZM21.5313 10.9688C21.5313 9.24484 20.8464 7.59154 19.6274 6.37256C18.4085 5.15357 16.7552 4.46875 15.0313 4.46875C13.3073 4.46875 11.654 5.15357 10.4351 6.37256C9.21607 7.59154 8.53125 9.24484 8.53125 10.9688C8.53125 12.6927 9.21607 14.346 10.4351 15.5649C11.654 16.7839 13.3073 17.4688 15.0313 17.4688C16.7552 17.4688 18.4085 16.7839 19.6274 15.5649C20.8464 14.346 21.5313 12.6927 21.5313 10.9688Z"
    />
  </svg>
);

export default IconSearchDesktop;
