import React from "react";
import { string, number } from 'prop-types';
import css from "./Icon.module.css";

const Icon = (props) => {
  const { 
    className, 
    name,
    fill,
    stroke,
    size = 24
  } = props

  return (
    <svg className={`${className || ""} ${css.Icon}`} fill={fill} stroke={stroke} width={size} height={size}>
      <use xlinkHref={`#${name}`} />
    </svg>
  )
}

Icon.defaultProps = {
  name: null,
}

Icon.propTypes = {
  name: string,
  fill: string,
  stroke: string,
  className: string,
  size: number,
}

export default Icon;