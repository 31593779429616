import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { IconEdit, IconSuccess, PrimaryButton, InlineTextButton } from '../../components';
import css from './StripeConnectAccountStatusBox.module.css';
import { humanizeSnakeCase } from '../../util/helpers';

const STATUS_VERIFICATION_NEEDED = 'verificationNeeded';
const STATUS_VERIFICATION_SUCCESS = 'verificationSuccess';
const STATUS_VERIFICATION_ERROR = 'verificationError';

const capabilitiesNamesMap = {
  card_payments: 'Debit/Credit card payments',
  afterpay_clearpay_payments: 'Afterpay payments',
};

const StripeConnectAccountStatusBox = props => {
  const { type, stripeAccount, onGetStripeConnectAccountLink, inProgress, disabled } = props;

  const joinWithBreaks = (item, index) => (
    <>
      {index !== 0 ? <br /> : null}
      {item}
    </>
  );

  const missingRequirements = stripeAccount
    ? [
        ...new Set(
          ['past_due', 'currently_due'].flatMap(type => {
            return (
              stripeAccount.requirements[type]?.map(req =>
                humanizeSnakeCase(req.split('.').join('_'))
              ) || []
            );
          })
        ),
      ].map(joinWithBreaks)
    : [];

  const disabledCapabilities = stripeAccount
    ? ['card_payments', 'afterpay_clearpay_payments']
        .filter(capability => {
          return stripeAccount.capabilities[capability] === 'inactive';
        })
        .map(capability => capabilitiesNamesMap[capability])
        .map(joinWithBreaks)
    : [];

  const stripeRequirementsBreakdown = stripeAccount?.details_submitted ? (
    <>
      <div>
        {missingRequirements.length ? (
          <>
            <div className={css.verificationBoxTitle}>Missing or invalid requirements:</div>
            <div className={css.verificationBoxText}>{missingRequirements}</div>
          </>
        ) : null}
      </div>
      <div>
        {disabledCapabilities.length ? (
          <>
            <div className={css.verificationBoxTitle}>Disabled capabilities:</div>
            <div className={css.verificationBoxText}>{disabledCapabilities}</div>
          </>
        ) : null}
      </div>
    </>
  ) : null;

  if (type === STATUS_VERIFICATION_NEEDED) {
    return (
      <div className={classNames(css.verificationBox, css.verficiationNeededBox)}>
        <div className={css.verificationBoxTextWrapper}>
          <div>
            <div className={css.verificationBoxTitle}>
              <FormattedMessage id="StripeConnectAccountStatusBox.verificationNeededTitle" />
            </div>
            <div className={css.verificationBoxText}>
              <FormattedMessage id="StripeConnectAccountStatusBox.verificationNeededText" />
            </div>
          </div>
          {stripeRequirementsBreakdown}
        </div>

        <PrimaryButton
          className={css.getVerifiedButton}
          spinnerClassName={css.spinner}
          type="button"
          inProgress={inProgress}
          disabled={disabled}
          onClick={onGetStripeConnectAccountLink}
        >
          <FormattedMessage id="StripeConnectAccountStatusBox.getVerifiedButton" />
        </PrimaryButton>
      </div>
    );
  } else if (type === STATUS_VERIFICATION_SUCCESS) {
    return (
      <div className={classNames(css.verificationBox, css.verficiationSuccessBox)}>
        <div
          className={classNames(
            css.verificationBoxTextWrapper,
            css.verificationBoxSuccessTextWrapper
          )}
        >
          <div className={css.verificationBoxTitle}>
            <IconSuccess className={css.icon} />
            <FormattedMessage id="StripeConnectAccountStatusBox.verificationSuccessTitle" />
          </div>
        </div>

        <InlineTextButton
          className={css.editVerificationButton}
          spinnerClassName={css.spinner}
          type="button"
          inProgress={inProgress}
          disabled={disabled}
          onClick={onGetStripeConnectAccountLink}
        >
          <IconEdit className={css.icon} pencilClassName={css.iconEditPencil} />
          <FormattedMessage id="StripeConnectAccountStatusBox.editAccountButton" />
        </InlineTextButton>
      </div>
    );
  } else if (type === STATUS_VERIFICATION_ERROR) {
    return (
      <div className={classNames(css.verificationBox, css.verficiationErrorBox)}>
        <div className={css.verificationBoxTextWrapper}>
          <div className={css.verificationBoxTitle}>
            <FormattedMessage id="StripeConnectAccountStatusBox.verificationFailedTitle" />
          </div>
          <div className={css.verificationBoxText}>
            <FormattedMessage id="StripeConnectAccountStatusBox.verificationFailedText" />
          </div>
          {stripeRequirementsBreakdown}
        </div>

        <PrimaryButton
          className={css.getVerifiedButton}
          spinnerClassName={css.spinner}
          type="button"
          inProgress={inProgress}
          disabled={disabled}
          onClick={onGetStripeConnectAccountLink}
        >
          <FormattedMessage id="StripeConnectAccountStatusBox.getVerifiedButton" />
        </PrimaryButton>
      </div>
    );
  } else {
    throw new Error(`Unknown type ${type} for StripeConnectAccountStatusBox`);
  }
};

export default StripeConnectAccountStatusBox;
