/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  // {
  //   id: 'wishlist',
  //   label: 'Wishlist',
  //   type: 'SelectSingleFilter',
  //   group: 'primary',
  //   queryParamNames: ['pub_wishlist'],
  //   config: {
  //     // Schema type is enum for SelectSingleFilter
  //     schemaType: 'enum',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for the UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'wishlist', label: 'Wishlist' },
  //     ],
  //   },
  // },
  {
    id: 'category',
    label: 'Category',
    // type: 'SelectSingleFilter',
    type:'SelectCategoryFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'beds', label: 'Beds', child: ['abc','syz'] },
        { key: 'casegoods_and_storage', label: 'Casegoods & Storage',  child: ['abc','syz']  },
        { key: 'seating', label: 'Seating', child: ['abc','syz']  },
        { key: 'tables', label: 'Tables', child: ['abc','syz']  },
      ],
    },
  },
  // {
  //   id: 'size',
  //   label: 'Size (US)',
  //   type: 'SelectMultipleFilter',
  //   group: 'primary',
  //   queryParamNames: ['pub_size'],
  //   config: {
  //     // Schema type options: 'enum', 'multi-enum'
  //     // Both types can work so that user selects multiple values when filtering search results.
  //     // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
  //     // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
  //     schemaType: 'enum',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for the UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: '4', label: '4' },
  //       { key: '5', label: '5' },
  //       { key: '6', label: '6' },
  //       { key: '7', label: '7' },
  //       { key: '8', label: '8' },
  //       { key: '9', label: '9' },
  //       { key: '10', label: '10' },
  //       { key: '11', label: '11' },
  //       { key: '12', label: '12' },
  //     ],
  //   },
  // },


  {
    id: 'color',
    label: 'Color',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_color'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      // searchMode: 'has_any',
      options: [

        { key: 'beige', label: 'Beige' },
        { key: 'black', label: 'Black' },
        { key: 'blue', label: 'Blue' },
        { key: 'green', label: 'Green' },
        { key: 'grey', label: 'Grey' },
        { key: 'gold', label: 'Gold' },
        { key: 'multicolored', label: 'Multicolored' },
        { key: 'orange', label: 'Orange' },
        { key: 'pink', label: 'Pink' },
        { key: 'purple', label: 'Purple' },

        { key: 'red', label: 'Red' },
        { key: 'silver', label: 'Silver' },
        { key: 'white', label: 'White' },
        { key: 'yellow', label: 'Yellow' },
        { key: 'unknown', label: 'Unknown' },
        { key: 'other', label: 'Other' },

      ],
    },
  },


  // {
  //   id: 'brand',
  //   label: 'Brand',
  //   type: 'SelectMultipleFilter',
  //   group: 'primary',
  //   queryParamNames: ['pub_brand'],
  //   config: {
  //     // Schema type options: 'enum', 'multi-enum'
  //     // Both types can work so that user selects multiple values when filtering search results.
  //     // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
  //     // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
  //     schemaType: 'enum',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for the UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'adidas', label: 'Adidas' },
  //       { key: 'air_jordan', label: 'Air Jordan' },
  //       { key: 'converse', label: 'Converse' },
  //       { key: 'new_balance', label: 'New Balance' },
  //       { key: 'nike', label: 'Nike' },
  //       { key: 'puma', label: 'Puma' },
  //       { key: 'ultraboost', label: 'Ultraboost' },
  //       { key: 'vans', label: 'Vans' },
  //       { key: 'yeezy', label: 'Yeezy' },
  //       { key: 'other', label: 'Other' },
  //     ],
  //   },
  // },

  {
    id: 'style',
    label: 'Style',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_style'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'mid_centrury_modern', label: 'Mid-Century Modern' },
        { key: 'modern', label: 'Modern' },
        { key: 'traditional', label: 'Traditional' },
        { key: 'contemporary', label: 'Contemporary' },
        { key: 'italian', label: 'Italian' },
        { key: 'art_deco', label: 'Art Deco' },
        { key: 'hollywood_regency', label: 'Hollywood Regency ' },
        { key: 'boho_chic', label: 'Boho Chic' },
        { key: 'shabby_chic', label: 'Shabby Chic' },
        { key: 'american', label: 'American' },

        { key: 'art_nouveau', label: 'Art Nouveau' },
        { key: 'arts_and_crafts', label: 'Arts & Crafts' },
        { key: 'asian', label: 'Asian' },
        { key: 'bauhaus', label: 'Bauhaus' },
        { key: 'campaign', label: 'Campaign' },
        { key: 'children', label: 'Children’s' },

        { key: 'chippendale', label: 'Chippendale' },
        { key: 'coastal', label: 'Coastal' },
        { key: 'english', label: 'English' },
        { key: 'french', label: 'French' },
        { key: 'gustavian', label: 'Gustavian' },
        { key: 'industrial', label: 'Industrial' },

        { key: 'minimalism', label: 'Minimalism' },
        { key: 'native_american', label: 'Native American        ' },
        { key: 'nautical', label: 'Nautical' },
        { key: 'postmodern', label: 'Postmodern' },
        { key: 'rustic', label: 'Rustic' },
        { key: 'rustic_european', label: 'Rustic European' },

        { key: 'spanish', label: 'Spanish' },
        { key: 'transitional', label: 'Transitional' },
        { key: 'other', label: 'Other' },
      ],
    },
  },

  {
    id: 'material',
    label: 'Material',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_material'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'acrylic', label: 'Acrylic' },
        { key: 'bamboo', label: 'Bamboo' },
        { key: 'birch', label: 'Birch' },
        { key: 'elm', label: 'Elm' },
        { key: 'fabric', label: 'Fabric' },
        { key: 'faux_bambo', label: 'Faux Bamboo' },
        { key: 'mahoganay', label: 'Mahogany' },
        { key: 'maple', label: 'Maple' },
        { key: 'masonite', label: 'Masonite' },
        { key: 'oak', label: 'Oak' },

        { key: 'pine', label: 'Pine' },
        { key: 'plywood', label: 'Plywood' },
        { key: 'resin', label: 'Resin' },
        { key: 'teak', label: 'Teak' },
        { key: 'timber', label: 'Timber' },

        { key: 'veneer', label: 'Veneer' },
        { key: 'walnut', label: 'Walnut' },
        { key: 'wicker', label: 'Wicker' },
        { key: 'unknown', label: 'Unknown' },

      ],
    },
  },


  // {
  //   id: 'brand_sub',
  //   label: 'Brand SubCategory',
  //   type: 'SelectMultipleFilter',
  //   group: 'primary',
  //   queryParamNames: ['pub_brand'],
  //   config: {
  //     // Schema type options: 'enum', 'multi-enum'
  //     // Both types can work so that user selects multiple values when filtering search results.
  //     // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
  //     // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
  //     schemaType: 'enum',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for the UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'adidas', label: 'Adidas' },
  //       { key: 'air_jordan', label: 'Air Jordan' },
  //       { key: 'converse', label: 'Converse' },
  //       { key: 'new_balance', label: 'New Balance' },
  //       { key: 'nike', label: 'Nike' },
  //       { key: 'puma', label: 'Puma' },
  //       { key: 'ultraboost', label: 'Ultraboost' },
  //       { key: 'vans', label: 'Vans' },
  //       { key: 'yeezy', label: 'Yeezy' },
  //       { key: 'other', label: 'Other' },
  //     ],
  //   },
  // },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 100000,
      step: 5,
    },
  },
  {
    id: 'sale',
    label: 'Sale',
    type: 'SingleCheckboxFilter',
    group: 'primary',
    queryParamNames: ['pub_isSale'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      option: { key: 'true', label: '' },
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },

  // Here is an example of multi-enum search filter.
  //
  // {
  //   id: 'amenities',
  //   label: 'Amenities',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_amenities'],
  //   config: {
  //     // Schema type options: 'enum', 'multi-enum'
  //     // Both types can work so that user selects multiple values when filtering search results.
  //     // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
  //     // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
  //     schemaType: 'multi-enum',

  //     // Optional modes: 'has_all', 'has_any'
  //     // Note: this is relevant only for schema type 'multi-enum'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_all',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'towels', label: 'Towels' },
  //       { key: 'bathroom', label: 'Bathroom' },
  //       { key: 'swimming_pool', label: 'Swimming pool' },
  //       { key: 'barbeque', label: 'Barbeque' },
  //     ],
  //   },
  // },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keyword' to this list.
  conflictingFilters: [],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const listing = {
  // These should be listing details from public data with schema type: enum
  // SectionDetailsMaybe component shows these on listing page.
  enumFieldDetails: [
    'category',
    'color',
    'style',
    ,
    'material',
    'height',
    'width',
    'depth',
    'pieces',
    'weight',
  ],
};
