import React from 'react';
import PropTypes, { boolean } from 'prop-types';
import classNames from 'classnames';
import NamedLink from '../NamedLink/NamedLink';
import { InlineTextButton } from '../../components';
import navcss from './Navbar.module.css';
import { FormattedMessage } from 'react-intl';

import css from './Topbar.module.css';

const NavItem = props => {
  return (
    <div className={navcss.wrapper}>
      <div className={navcss.container}>
        <div className={navcss.navbar}>
          <div className={navcss.dropdown}>
            <NamedLink
              name="SearchPageCategory"
              params={{
                category: 'beds',
              }}
              className={navcss.dropbtn}
            >
              <FormattedMessage id="Footer.link1" />
            </NamedLink>
            <div className={navcss.dropdowncontent}>
              <NamedLink
                name="SearchPageSubcategory"
                params={{
                  category: 'beds',
                  subcategory: 'bed_frames',
                }}
                className={css.link}
              >
                Bed Frames
              </NamedLink>
              <NamedLink
                name="SearchPageSubcategory"
                params={{
                  category: 'beds',
                  subcategory: 'canopy_beds',
                }}
                className={css.link}
              >
                Canopy Beds
              </NamedLink>
              <NamedLink
                name="SearchPageSubcategory"
                params={{
                  category: 'beds',
                  subcategory: 'headboards',
                }}
                className={css.link}
              >
                Headboards
              </NamedLink>
            </div>
          </div>

          <div className={navcss.dropdown}>
            <NamedLink
              name="SearchPageCategory"
              params={{
                category: 'casegoods_and_storage',
              }}
              className={navcss.dropbtn}
            >
              <FormattedMessage id="Footer.link2" />
            </NamedLink>
            <div className={navcss.dropdowncontent}>
              <NamedLink
                name="SearchPageSubcategory"
                params={{
                  category: 'casegoods_and_storage',
                  subcategory: 'armoires_wardrobes',
                }}
                className={css.link}
              >
                Armoires & Wardrobes
              </NamedLink>
              <NamedLink
                name="SearchPageSubcategory"
                params={{
                  category: 'casegoods_and_storage',
                  subcategory: 'bookcases',
                }}
                className={css.link}
              >
                Bookcases
              </NamedLink>

              <NamedLink
                name="SearchPageSubcategory"
                params={{
                  category: 'casegoods_and_storage',
                  subcategory: 'china_display_cabinets',
                }}
                className={css.link}
              >
                China & Display Cabinets
              </NamedLink>

              <NamedLink
                name="SearchPageSubcategory"
                params={{
                  category: 'casegoods_and_storage',
                  subcategory: 'credenzas',
                }}
                className={css.link}
              >
                Credenzas
              </NamedLink>

              <NamedLink
                name="SearchPageSubcategory"
                params={{
                  category: 'casegoods_and_storage',
                  subcategory: 'dresser_chest_drawers',
                }}
                className={css.link}
              >
                Dressers & Chests of Drawers
              </NamedLink>

              <NamedLink
                name="SearchPageSubcategory"
                params={{
                  category: 'casegoods_and_storage',
                  subcategory: 'etageres',
                }}
                className={css.link}
              >
                Etageres
              </NamedLink>

              <NamedLink
                name="SearchPageSubcategory"
                params={{
                  category: 'casegoods_and_storage',
                  subcategory: 'vanities',
                }}
                className={css.link}
              >
                Vanities
              </NamedLink>

              <NamedLink
                name="SearchPageSubcategory"
                params={{
                  category: 'casegoods_and_storage',
                  subcategory: 'nightstands',
                }}
                className={css.link}
              >
                Nightstands
              </NamedLink>
            </div>
          </div>

          <div className={navcss.dropdown}>
            <NamedLink
              name="SearchPageCategory"
              params={{
                category: 'tables',
              }}
              className={navcss.dropbtn}
            >
              <FormattedMessage id="Footer.link3" />
            </NamedLink>
            <div className={navcss.dropdowncontent}>
              <NamedLink
                name="SearchPageSubcategory"
                params={{
                  category: 'tables',
                  subcategory: 'accent_tables',
                }}
                className={css.link}
              >
                Accent Tables
              </NamedLink>

              <NamedLink
                name="SearchPageSubcategory"
                params={{
                  category: 'tables',
                  subcategory: 'coffee_table',
                }}
                className={css.link}
              >
                Coffee Table
              </NamedLink>

              <NamedLink
                name="SearchPageSubcategory"
                params={{
                  category: 'tables',
                  subcategory: 'desks',
                }}
                className={css.link}
              >
                Desks
              </NamedLink>

              <NamedLink
                name="SearchPageSubcategory"
                params={{
                  category: 'tables',
                  subcategory: 'dining_table',
                }}
                className={css.link}
              >
                Dining Table
              </NamedLink>

              <NamedLink
                name="SearchPageSubcategory"
                params={{
                  category: 'tables',
                  subcategory: 'console_table',
                }}
                className={css.link}
              >
                Console Tables
              </NamedLink>
            </div>
          </div>

          <div className={navcss.dropdown}>
            <NamedLink
              name="SearchPageCategory"
              params={{
                category: 'seating',
              }}
              className={navcss.dropbtn}
            >
              <FormattedMessage id="Footer.link4" />
            </NamedLink>
            <div className={navcss.dropdowncontent}>
              <NamedLink
                name="SearchPageSubcategory"
                params={{
                  category: 'seating',
                  subcategory: 'accent_chair',
                }}
                className={css.link}
              >
                Accent Chairs
              </NamedLink>

              <NamedLink
                name="SearchPageSubcategory"
                params={{
                  category: 'seating',
                  subcategory: 'benches',
                }}
                className={css.link}
              >
                Benches
              </NamedLink>

              <NamedLink
                name="SearchPageSubcategory"
                params={{
                  category: 'seating',
                  subcategory: 'barstools',
                }}
                className={css.link}
              >
                Barstools
              </NamedLink>

              <NamedLink
                name="SearchPageSubcategory"
                params={{
                  category: 'seating',
                  subcategory: 'chair_set',
                }}
                className={css.link}
              >
                Chair Sets
              </NamedLink>

              <NamedLink
                name="SearchPageSubcategory"
                params={{
                  category: 'seating',
                  subcategory: 'dining_chair',
                }}
                className={css.link}
              >
                Dining Chairs
              </NamedLink>

              <NamedLink
                name="SearchPageSubcategory"
                params={{
                  category: 'seating',
                  subcategory: 'ottomans_footstools',
                }}
                className={css.link}
              >
                Ottomans & Footstools
              </NamedLink>
            </div>
          </div>

          {/*<NamedLink*/}
          {/*  name="SearchPageSale"*/}
          {/*  // to={{*/}
          {/*  //   search: '?isSale=true',*/}
          {/*  // }}*/}
          {/*  // params={{*/}
          {/*  //   slug: "sale"*/}
          {/*  // }}*/}
          {/*  className={css.link}*/}
          {/*>*/}
          {/*  Sale*/}
          {/*</NamedLink>*/}

          <NamedLink
            name="ArtistsPage"
            to={{
              artists: '',
            }}
            className={css.link}
          >
            Artists
          </NamedLink>
        </div>
      </div>
    </div>
  );
};

const { bool } = PropTypes;

NavItem.defaultProps = {
  mobilemenu: false,
};

NavItem.propTypes = {
  mobilemenu: bool,
};

export default NavItem;
