import React, { Fragment } from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaPinterest,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.module.css';

const renderSocialMediaLinks = intl => {
  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToPinterest = intl.formatMessage({ id: 'Footer.goToPinterest' });

  const fbLink = (
    <ExternalLink
      key="linkToFacebook"
      href="https://www.facebook.com/encoresociety"
      className={css.icon}
      title={goToFb}
    >
      <IconSocialMediaFacebook />
    </ExternalLink>
  );

  const pinterestLink = (
    <ExternalLink
      key="linkToTwitter"
      href="https://www.pinterest.com/encoresociety"
      className={css.icon}
      title={goToPinterest}
    >
      <IconSocialMediaPinterest />
    </ExternalLink>
  );
  const instragramLink = (
    <ExternalLink
      key="linkToInstagram"
      href="https://www.instagram.com/encoresociety"
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  );
  return [fbLink, instragramLink, pinterestLink];
};

const footerLinks = [
  {
    title: 'Need help?',
    links: [
      {
        title: 'Help Center',
        source: 'https://support.encoresociety.com/hc/en-us',
        isNewFront: false,
      },
      {
        title: 'Contact Us',
        source: 'https://support.encoresociety.com/hc/en-us/articles/9824489858585',
        isNewFront: false,
      },
      {
        title: 'Return Policy',
        source: 'https://support.encoresociety.com/hc/en-us/articles/9824908098201',
        isNewFront: false,
      },
      {
        title: 'Shipping Policy',
        source:
          'https://support.encoresociety.com/hc/en-us/articles/9824881428377-Pickup-and-Delivery',
        isNewFront: false,
      },
    ],
  },
  {
    title: 'For buyers',
    links: [
      {
        title: 'Buyer FAQ',
        source:
          'https://support.encoresociety.com/hc/en-us/sections/9824803473305-Buying-on-Encore',
        isNewFront: false,
      },
      // { title: "Buy Now Pay Later", source: "/pay-later", isNewFront: true },
      {
        title: 'Trade Program',
        source: 'https://support.encoresociety.com/hc/en-us/articles/10694868597273',
      },
    ],
  },
  {
    title: 'For sellers',
    links: [
      {
        title: 'Seller FAQ',
        source:
          'https://support.encoresociety.com/hc/en-us/sections/9824494045849-Selling-on-Encore',
        isNewFront: false,
      },
    ],
  },
  {
    title: 'Shop',
    links: [
      { title: 'Shop All', source: '/catalog', isNewFront: false },
      { title: 'Shop by Artist', source: '/artists', isNewFront: false },
    ],
  },
  {
    title: 'Our company',
    links: [
      // {
      //   title: "About Us",
      //   source:
      //     "https://encore-society.helpscoutdocs.com/article/130-about-encore",
      //   isNewFront: false,
      // },
      {
        title: 'Privacy Policy',
        source: 'https://support.encoresociety.com/hc/en-us/articles/9824323201945-Privacy-Policy',
        isNewFront: false,
      },
      {
        title: 'Terms of Service',
        source:
          'https://support.encoresociety.com/hc/en-us/articles/9824391796249-Terms-of-Service',
        isNewFront: false,
      },
    ],
  },
];

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.container}>
        <div className={css.wrapper}>
          <div className={css.organization} id="organization">
            <NamedLink name="LandingPage" className={css.logoLink}>
              <Logo format="desktop" className={css.logo} />
            </NamedLink>
            <div className={css.organizationInfo}>
              <p className={css.organizationDescription}>
                Your community for buying and selling one-of-a-kind pieces of furniture
              </p>
            </div>
          </div>
          <div className={css.columnContainer}>
            {footerLinks.map(({ title, links }, index) => (
              <div key={index} className={css.linkColumn}>
                <span className={css.linkColumnTitle}>{title}</span>
                {links.map((link, index) => (
                  <a key={index} href={link.source} className={css.linkItem}>
                    {link.title}
                  </a>
                ))}
              </div>
            ))}
            <div className={css.linkColumn}>
              <span className={css.linkColumnTitle}>
                <FormattedMessage id="Footer.followUs" />
              </span>
              <div className={css.socialIconWrapper}>
                {socialMediaLinks.map((link, index) => (
                  <Fragment key={index}>{link}</Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
