import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.module.css';

const BasketIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootMobileIcons, className);

  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes}>
      <path id="Shop" d="M15,30A15,15,0,1,1,30,15,15.017,15.017,0,0,1,15,30ZM15,1.85A13.149,13.149,0,1,0,28.149,15,13.164,13.164,0,0,0,15,1.85Zm6.8,8.819H19.6V8.593a4.6,4.6,0,0,0-9.21,0V10.67H8.2L7.544,24.6H22.456ZM12.369,8.593a2.631,2.631,0,0,1,5.263,0V10.67H12.369Z" fill="#262626"/>
    </svg>
  );
};

const { string } = PropTypes;

BasketIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

BasketIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default BasketIcon;
