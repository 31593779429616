import unionWith from 'lodash/unionWith';
import config from '../../config';
import { storableError } from '../../util/errors.js';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { isOriginInUse, isStockInUse } from '../../util/search';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

import { types as sdkTypes } from '../../util/sdkLoader';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { getRecentlyViewedIds } from '../../util/helpers';

const { UUID } = sdkTypes;

export const FEATURED_LISTINGS_REQUEST = 'app/LandingPage/FEATURED_LISTINGS_REQUEST';
export const FEATURED_LISTINGS_SUCCESS = 'app/LandingPage/FEATURED_LISTINGS_SUCCESS';
export const FEATURED_LISTINGS_ERROR = 'app/LandingPage/FEATURED_LISTINGS_ERROR';

export const RECENTLY_LISTINGS_REQUEST = 'app/LandingPage/RECENTLY_LISTINGS_REQUEST';
export const RECENTLY_LISTINGS_SUCCESS = 'app/LandingPage/RECENTLY_LISTINGS_SUCCESS';
export const RECENTLY_LISTINGS_ERROR = 'app/LandingPage/RECENTLY_LISTINGS_ERROR';

export const NEWARRIVALS_LISTINGS_REQUREST = 'app/LandingPage/NEWARRIVALS_LISTINGS_REQUREST';
export const NEWARRIVALS_LISTINGS_SUCCESS = 'app/LandingPage/NEWARRIVALS_LISTINGS_REQURES';
export const NEWARRIVALS_LISTINGS_ERROR = 'app/LandingPage/NEWARRIVALS_LISTINGS_REQURES';

const RESULT_PAGE_SIZE = 8;
const RESULT_PAGE_ARRIVALS_SIZE = 10;

// ================ Reducer ================ //
const initialState = {
  featuredListings: [],
  featuredListingMetaData: {},
  currentFeaturedIds: [],
  arrivalsListingsIds: [],
  recentlyListingsInProgress: false,
  recentlyListingsError: false,
  arrivalsListingsInProgress: false,
  arrivalsListingsError: false,
  featuredListingInProgress: false,
  featuredListingsError: false,
};

const resultIds = data => data.data.map(l => l.id);

const listingFeaturedReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case NEWARRIVALS_LISTINGS_REQUREST:
      return {
        ...state,
        arrivalsListingsInProgress: true,
        arrivalsListingsError: null,
      };
    case NEWARRIVALS_LISTINGS_SUCCESS:
      return {
        ...state,
        arrivalsListingsIds: resultIds(payload.data),
        arrivalsListingsInProgress: false,
      };
    case NEWARRIVALS_LISTINGS_ERROR:
      return {
        ...state,
        arrivalsListingsInProgress: false,
        arrivalsListingsError: payload,
      };
    case FEATURED_LISTINGS_REQUEST:
      return {
        ...state,
        featuredListingInProgress: true,
        featuredListingsError: null,
      };
    case FEATURED_LISTINGS_SUCCESS:
      return {
        ...state,
        featuredListings: payload.data,
        featuredListingMetaData: payload.meta,
        currentFeaturedIds: resultIds(payload.data),
        featuredListingInProgress: false,
      };
    case FEATURED_LISTINGS_ERROR:
      return { ...state, featuredListingInProgress: false, featuredListingsError: payload };
    case RECENTLY_LISTINGS_REQUEST:
      return {
        ...state,
        recentlyListingsInProgress: true,
        recentlyListingsError: null,
      };
    case RECENTLY_LISTINGS_SUCCESS:
      return {
        ...state,
        recentlyListingsInProgress: false,
      };
    case RECENTLY_LISTINGS_ERROR:
      return {
        ...state,
        recentlyListingsInProgress: false,
        recentlyListingsError: payload,
      };
    default:
      return state;
  }
};

export default listingFeaturedReducer;

// ================ Action creators ================ //
export const featuredListingsRequest = searchParams => ({
  type: FEATURED_LISTINGS_REQUEST,
});

export const featuredListingsSuccess = response => ({
  type: FEATURED_LISTINGS_SUCCESS,
  payload: { data: response.data, meta: response.data.meta },
});

export const featuredListingsError = e => ({
  type: FEATURED_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const arrivalsListingRequest = searchParams => ({
  type: NEWARRIVALS_LISTINGS_REQUREST,
  payload: { searchParams },
});

export const arrivalsListingSuccess = response => ({
  type: NEWARRIVALS_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const arrivalsListingError = e => ({
  type: NEWARRIVALS_LISTINGS_ERROR,
  error: true,
  payload: e,
});
////////
export const recentlyListingsRequest = () => ({
  type: RECENTLY_LISTINGS_REQUEST,
});

export const recentlyListingsSuccess = response => ({
  type: RECENTLY_LISTINGS_SUCCESS,
  payload: { data: response.data, meta: response.data.meta },
});

export const recentlyListingsError = e => ({
  type: RECENTLY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const getNewArrivalsListing = searchParams => (dispatch, getState, sdk) => {
  const { perPage, sort, ...rest } = searchParams;

  const params = {
    ...rest,
    sort: sort,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(arrivalsListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(arrivalsListingError(storableError(e)));
      throw e;
    });
};

export const getFeaturedListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(featuredListingsRequest(searchParams));

  const { perPage, meta, ...rest } = searchParams;

  const totalPages = meta && Object.keys(meta).length ? meta.totalPages : 1;
  const page = totalPages > 1 ? Math.floor(Math.random() * totalPages) : totalPages;

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;
  const sortMaybe = config.custom.sortConfig.options[0].key;

  const params = {
    ...rest,
    sort: sortMaybe,
    perPage: RESULT_PAGE_ARRIVALS_SIZE,
    include: ['author', 'images'],
    'fields.listing': ['title', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
    pub_featured: true,
    page,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(featuredListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(featuredListingsError(storableError(e)));
      throw e;
    });
};

export const getRecentlyListings = ids => (dispatch, getState, sdk) => {
  dispatch(recentlyListingsRequest());

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;
  const sortMaybe = config.custom.sortConfig.options[0].key;

  const params = {
    ids,
    sort: sortMaybe,
    include: ['author', 'images'],
    'fields.listing': ['title', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.bio'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(recentlyListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(recentlyListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search, meta) => (dispatch, getState) => {
  const minStockMaybe = isStockInUse(config) ? { minStock: 1 } : {};
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;
  const originMaybe = isOriginInUse(config) && origin ? { origin } : {};
  const sortMaybe = config.custom.sortConfig.options[0].key;

  return Promise.all([
    dispatch(
      getFeaturedListings({
        ...minStockMaybe,
        ...originMaybe,
        meta,
        include: ['author', 'images'],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
        'limit.images': 1,
      })
    ),
    dispatch(
      getNewArrivalsListing({
        ...minStockMaybe,
        ...originMaybe,
        sort: sortMaybe,
        perPage: RESULT_PAGE_ARRIVALS_SIZE,
        include: ['author', 'images'],
        'fields.listing': ['title', 'price', 'publicData'],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
        'limit.images': 1,
      })
    ),
    dispatch(fetchCurrentUser()).then(() => {
      const currentUser = getState().user.currentUser;
      const recentlyViewedIds = getRecentlyViewedIds(currentUser);
      return dispatch(getRecentlyListings(recentlyViewedIds));
    }),
  ]);
};
