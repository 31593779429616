import pick from 'lodash/pick';

import config from '../../config';
import { types as sdkTypes, util as sdkUtil, createImageVariantConfig } from '../../util/sdkLoader';
import { getStartOf, addTime } from '../../util/dates';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { initiatePrivileged, transactionLineItems, transitionPrivileged } from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import { userFollow, getUserById } from '../../util/api';
import { TRANSITION_ENQUIRE, TRANSITION_REQUEST_PAYMENT } from '../../util/transaction';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import {
  fetchCurrentUser,
  fetchCurrentUserHasOrdersSuccess,
  currentUserShowSuccess,
} from '../../ducks/user.duck';
import { getRecentlyListings } from '../LandingPage/LandingPage.duck';
// import { updateCartItemsSuccess } from '../CartPage/CartPage.duck';
import moment from 'moment';
import { getRecentlyViewedIds } from '../../util/helpers';
import { OfferStatusEnum } from '../../util/enum';
import { INITIATE_ORDER_ERROR, INITIATE_ORDER_SUCCESS } from '../CheckoutPage/CheckoutPage.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //
export const UPDATE_USER_REQUEST = 'app/UserFollow/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'app/UserFollow/UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'app/UserFollow/UPDATE_USER_ERROR';

export const UPDATE_USER_RECENTLY_VIEWED_REQUEST =
  'app/UserRecentlyViewed/UPDATE_USER_RECENTLY_VIEWED_REQUEST';
export const UPDATE_USER_RECENTLY_VIEWED_SUCCESS =
  'app/UserRecentlyViewed/UPDATE_USER_RECENTLY_VIEWED_SUCCESS';
export const UPDATE_USER_RECENTLY_VIEWED_ERROR =
  'app/UserRecentlyViewed/UPDATE_USER_RECENTLY_VIEWED_ERROR';

export const UPDATE_LIKES_REQUEST = 'app/ListingPage/UPDATE_LIKES_REQUEST';
export const UPDATE_LIKES_SUCCESS = 'app/ListingPage/UPDATE_LIKES_SUCCESS';
export const UPDATE_LIKES_ERROR = 'app/ListingPage/UPDATE_LIKES_ERROR';

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  enquiryModalOpenForListingId: null,
  updateLikesError: null,
  updateLikesInProgress: false,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_LIKES_REQUEST:
      return { ...state, updateLikesInProgress: true, updateLikesError: null };
    case UPDATE_LIKES_SUCCESS:
      return { ...state, updateLikesInProgress: false };
    case UPDATE_LIKES_ERROR:
      return { ...state, updateLikesInProgress: false, updateLikesError: payload };

    case UPDATE_USER_REQUEST: {
      return { ...state, updateUserInProgress: true };
    }
    case UPDATE_USER_SUCCESS: {
      return { ...state, updateUserInProgress: false };
    }
    case UPDATE_USER_ERROR: {
      return { ...state, updateUserInProgress: false, updateUserError: payload };
    }

    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST:
      return { ...state, fetchTimeSlotsError: null };
    case FETCH_TIME_SLOTS_SUCCESS:
      return { ...state, timeSlots: payload };
    case FETCH_TIME_SLOTS_ERROR:
      return { ...state, fetchTimeSlotsError: payload };

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const updateLikesRequest = params => ({
  type: UPDATE_LIKES_REQUEST,
  payload: { params },
});
export const updateLikesSuccess = result => ({
  type: UPDATE_LIKES_SUCCESS,
  payload: result.data,
});
export const updateLikesError = error => ({
  type: UPDATE_LIKES_ERROR,
  payload: error,
  error: true,
});

// UPDATE USER FOLLOW
export const updateUserRequest = params => ({
  type: UPDATE_USER_REQUEST,
  payload: { params },
});
export const updateUserSuccess = () => ({
  type: UPDATE_USER_SUCCESS,
});
export const updateUserError = error => ({
  type: UPDATE_USER_ERROR,
  payload: error,
  error: true,
});

// UPDATE USER RECENTLY VIEWED
export const updateUserRecentlyViewedRequest = params => ({
  type: UPDATE_USER_REQUEST,
  payload: { params },
});
export const updateUserRecentlyViewedSuccess = () => ({
  type: UPDATE_USER_SUCCESS,
});
export const updateUserRecentlyViewedError = error => ({
  type: UPDATE_USER_ERROR,
  payload: error,
  error: true,
});

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = () => ({ type: FETCH_TIME_SLOTS_REQUEST });
export const fetchTimeSlotsSuccess = timeSlots => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: timeSlots,
});
export const fetchTimeSlotsError = error => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: error,
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

// ================ Thunks ================ //

export const followUser = ({ followerId = '', followingId = '', isUnfollowAvailable = true }) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(updateUserRequest());

  if (followerId) {
    getUserById({ id: followerId.uuid })
      .then(res => {
        const userFollowing = res.data.data.attributes.profile.publicData;

        getUserById({ id: followingId.uuid })
          .then(res => {
            const userFollower = res.data.data.attributes.profile.publicData;

            const isFollower = !!userFollowing.following?.some(
              followingID => followingID === followingId.uuid
            );
            const isFollowing = !!userFollower.followers?.some(
              followerID => followerID === followerId.uuid
            );
            const followingIndex =
              isFollowing &&
              userFollowing.following.findIndex(followingID => followingID === followingId.uuid);
            const followerIndex =
              isFollower &&
              userFollower.followers.findIndex(followerID => followerID === followerId.uuid);

            if (
              followingIndex !== false &&
              followingIndex !== -1 &&
              followerIndex !== false &&
              followerIndex !== -1
            ) {
              if (isUnfollowAvailable) {
                userFollowing.following.splice(followingIndex, 1);
                userFollower.followers.splice(followerIndex, 1);
              }
            } else {
              if (userFollowing.following) {
                userFollowing.following.push(followingId.uuid);
              } else {
                userFollowing.following = [followingId.uuid];
              }
              if (userFollower.followers) {
                userFollower.followers.push(followerId.uuid);
              } else {
                userFollower.followers = [followerId.uuid];
              }
            }

            if (
              Object.keys(userFollowing).length &&
              followerId &&
              Object.keys(userFollower).length &&
              followingId
            ) {
              userFollow({
                id: followerId,
                publicData: {
                  ...userFollowing,
                },
              })
                .then(res => {
                  userFollow({
                    id: followingId,
                    publicData: {
                      ...userFollower,
                    },
                  })
                    .then(res => {
                      dispatch(fetchCurrentUser());
                    })
                    .catch(err => {
                      console.log(err.message);
                      throw new Error(err.message);
                    });
                })
                .catch(err => {
                  console.log(err.message);
                  throw new Error(err.message);
                });
            }
          })
          .catch(err => {
            console.log(err);
          });
      })
      .catch(err => {
        console.log(err);
      });
  } else {
    localStorage.setItem('following', JSON.stringify([followingId.uuid]));
  }
};

export const updateLikes = (listingId, history) => (dispatch, getState, sdk) => {
  dispatch(updateLikesRequest());

  return dispatch(fetchCurrentUser()).then(() => {
    const currentUser = getState().user.currentUser;
    const currentLikes =
      currentUser?.attributes?.profile?.privateData?.likedListings ||
      JSON.parse(localStorage.getItem('likedListings') || '[]');
    const queryParams = {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    // if listingId already exists in currentLikes, it should be removed from currentLikes
    // if user has current likes, merge listingId into current likes
    const ifDislike = !!currentLikes?.includes(listingId);
    const likedListings = ifDislike
      ? currentLikes.filter(id => id !== listingId)
      : currentLikes
      ? [...currentLikes, listingId]
      : [listingId];

    if (currentLikes.length !== likedListings.length) {
      if (!currentUser) {
        localStorage.setItem('likedListings', JSON.stringify(likedListings));
        if (history) {
          history.push('/wishlist');
        }
      } else {
        return sdk.currentUser
          .updateProfile({ privateData: { likedListings } }, queryParams)
          .then(response => {
            dispatch(updateLikesSuccess(response));

            const entities = denormalisedResponseEntities(response);
            if (entities.length !== 1) {
              throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
            }
            const currentUser = entities[0];

            // Update current user in state.user.currentUser through user.duck.js
            dispatch(currentUserShowSuccess(currentUser));
          })
          .catch(e => {
            dispatch(updateLikesError(storableError(e)));
          });
      }
    }
    return Promise.resolve({});
  });
};

export const updateRecentlyListings = listingId => (dispatch, getState, sdk) => {
  dispatch(updateUserRecentlyViewedRequest());

  const newItem = { listingId, timestamp: moment.utc().unix() };

  const currentUser = getState().user.currentUser;
  const currentRecentlyViewed =
    currentUser?.attributes?.profile?.privateData?.recentlyViewed ||
    JSON.parse(localStorage.getItem('recentlyViewed') || '[]');
  const recentlyViewed =
    currentRecentlyViewed && Array.isArray(currentRecentlyViewed)
      ? currentRecentlyViewed
      : [newItem];

  const recentlyViewedFiltered = [
    newItem,
    ...recentlyViewed.filter(item => item.listingId !== newItem.listingId),
  ];
  recentlyViewedFiltered.splice(20);

  const queryParams = {
    expand: true,
    include: ['profileImage'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  };

  if (currentUser) {
    return sdk.currentUser
      .updateProfile({ privateData: { recentlyViewed: recentlyViewedFiltered } }, queryParams)
      .then(response => {
        dispatch(updateUserRecentlyViewedSuccess());
        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];

        // Update current user in state.user.currentUser through user.duck.js
        dispatch(currentUserShowSuccess(currentUser));
      })
      .catch(e => {
        dispatch(updateUserRecentlyViewedError(storableError(e)));
      });
  } else {
    localStorage.setItem('recentlyViewed', JSON.stringify(recentlyViewedFiltered));
  }
};

export const showListing = (listingId, isOwn = false) => (dispatch, getState, sdk) => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;

  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images', 'currentStock'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      dispatch(addMarketplaceEntities(data));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return Object.keys(params).length
    ? sdk.timeslots.query(params).then(response => {
        return denormalisedResponseEntities(response);
      })
    : Promise.resolve();
};

export const fetchTimeSlots = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchTimeSlotsRequest);

  // Time slots can be fetched for 90 days at a time,
  // for at most 180 days from now. If max number of bookable
  // day exceeds 90, a second request is made.

  const maxTimeSlots = 90;
  // booking range: today + bookable days -1
  const bookingRange = config.dayCountAvailableForBooking - 1;
  const timeSlotsRange = Math.min(bookingRange, maxTimeSlots);

  const now = new Date();
  const start = getStartOf(now, 'day', 'Etc/UTC');
  const end = addTime(start, timeSlotsRange, 'days', 'Etc/UTC');
  const params = { listingId, start, end };

  return dispatch(timeSlotsRequest(params))
    .then(timeSlots => {
      const secondRequest = bookingRange > maxTimeSlots;

      if (secondRequest) {
        const secondRange = Math.min(maxTimeSlots, bookingRange - maxTimeSlots);
        const secondParams = {
          listingId,
          start: end,
          end: addTime(end, secondRange, 'days', 'Etc/UTC'),
        };

        return dispatch(timeSlotsRequest(secondParams)).then(secondBatch => {
          const combined = timeSlots.concat(secondBatch);
          dispatch(fetchTimeSlotsSuccess(combined));
        });
      } else {
        dispatch(fetchTimeSlotsSuccess(timeSlots));
      }
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(storableError(e)));
    });
};

export const sendEnquiry = (listingId, message) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.transactionProcessAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sendEnquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

export const fetchTransactionLineItems = ({ orderData, listingId, isOwnListing }) => (
  dispatch,
  getState
) => {
  dispatch(fetchLineItemsRequest());
  const currentUser = getState().user.currentUser;
  const { isTrader, isTaxExemption } = currentUser?.profile.attributes?.publicData || {};
  transactionLineItems({ orderData, listingId, isOwnListing, isTrader, isTaxExemption })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        orderData,
      });
    });
};

const initiateOfferSuccess = () => ({
  type: INITIATE_ORDER_SUCCESS,
});

const initiateOfferError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

export const initiateOffer = (offerParams, transactionId) => (dispatch, getState, sdk) => {
  const { offerPrice, listingId } = offerParams;
  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = TRANSITION_REQUEST_PAYMENT;

  // Parameters for Flex API
  const transitionParams = {
    protectedData: { offerPrice, offerStatus: OfferStatusEnum.PENDING },
    listingId,
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params: transitionParams,
      }
    : {
        processAlias: config.transactionProcessAlias,
        transition,
        params: transitionParams,
      };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSucces = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOfferSuccess());
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOfferError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-offer-failed', {
      ...transactionIdMaybe,
    });
    throw e;
  };

  if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  }
};

export const loadData = (params, search) => (dispatch, getState) => {
  const listingId = new UUID(params.id);

  // Clear old line-items
  dispatch(setInitialValues({ lineItems: null }));

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, true));
  }

  if (config.listingManagementType === 'availability') {
    return Promise.all([
      dispatch(showListing(listingId)),
      dispatch(fetchTimeSlots(listingId)),
      dispatch(fetchReviews(listingId)),
    ]);
  } else {
    return Promise.all([
      dispatch(showListing(listingId)),
      dispatch(fetchReviews(listingId)),
      dispatch(fetchCurrentUser()).then(() => {
        const currentUser = getState().user.currentUser;
        const recentlyViewedIds = getRecentlyViewedIds(currentUser, params.id);
        return dispatch(getRecentlyListings(recentlyViewedIds));
      }),
    ]);
  }
};
