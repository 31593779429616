export const gtmEvent = (action, params = {}, isECommerce = false) => {
  if (window.dataLayer) {
    const paramsName = isECommerce ? 'ecommerce' : 'params';
    window.dataLayer.push({ ecommerce: null, params: null });
    window.dataLayer.push({
      event: action,
      eevent: isECommerce,
      [paramsName]: params,
    });
  }
};

export const listingToGtagItem = (listing, index = undefined) => {
  return {
    item_id: listing.id.uuid,
    item_name: listing.attributes.title,
    discount: listing.attributes.publicData?.oldPrice?.amount
      ? (listing.attributes.publicData?.oldPrice?.amount - listing.attributes.price.amount) / 100
      : undefined,
    item_category: listing.attributes.publicData?.category,
    item_category2: listing.attributes.publicData?.subcategory,
    currency: listing.attributes.price.currency,
    price: listing.attributes.price.amount / 100,
    index: index,
    item_brand: listing.author?.attributes?.profile?.displayName,
  };
};
