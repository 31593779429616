import React from "react";
import { NavLink } from 'react-router-dom';
import css from "./Breadcrumbs.module.css";

const BreadcrumbsItem = (props) => {
  const { title, isActive, path, clickable = false, onClick } = props;

  return (
    <NavLink to={path} className={`${css.breadCrumbsItem} ${clickable ? css.clickable : css.disabled} ${isActive ? css.isActive : ''}`} onClick={onClick}>
      <div className={css.breadCrumbsLabel}>{ title }</div>
    </NavLink>
  )
}

export default BreadcrumbsItem;