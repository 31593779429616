import unionWith from 'lodash/unionWith';

import config from '../../config';
import { storableError } from '../../util/errors';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { parseDateFromISO8601, getExclusiveEndDate } from '../../util/dates';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { isOriginInUse, isStockInUse } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { rest } from 'lodash';
import { userLoggedin } from './wishlistconfig';
import { fetchCurrentUser } from '../../ducks/user.duck';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 24;

// ================ Action types ================ //

export const FETCh_USER = 'app/Fetch/User';


export const SEARCH_WISHLIST_REQUEST = 'app/WishList/SEARCH_WISHLIST_REQUEST';
export const SEARCH_WISHLIST_SUCCESS = 'app/WishList/SEARCH_WISHLIST_SUCCESS';
export const SEARCH_WISHLIST_ERROR = 'app/WishList/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_WISHLIST_REQUEST = 'app/WishList/SEARCH_MAP_WISHLIST_REQUEST';
export const SEARCH_MAP_WISHLIST_SUCCESS = 'app/WishList/SEARCH_MAP_WISHLIST_SUCCESS';
export const SEARCH_MAP_WISHLIST_ERROR = 'app/WishList/SEARCH_MAP_WISHLIST_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/WishList/SEARCH_MAP_SET_ACTIVE_LISTING';
export const USER_FETCH_SUCCESS = 'app/User/SEARCH'


// ================ Reducer ================ //

const initialState = {
  pagination: null,
  cardInitialize:[],
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
};

const resultIds = data => data.data.map(l => l.id);

const WishlistingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_WISHLIST_REQUEST:

      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_WISHLIST_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        listing:payload.data,
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_WISHLIST_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_WISHLIST_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_WISHLIST_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_WISHLIST_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    default:
      return state;
  }
};

export default WishlistingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_WISHLIST_REQUEST,
  payload: { searchParams },
});

// export const searchListingsSuccess = response => ({
//   type: SEARCH_WISHLIST_SUCCESS,
//   payload: { data: response.data },
// });
export const searchListingsSuccess = response => ({
    type: SEARCH_WISHLIST_SUCCESS,
    payload: { data: response.data },
  });

  export const userFetchSuccess = response => ({
    type: USER_FETCH_SUCCESS,
    payload: { data: response.data },
  });



export const searchListingsError = e => ({
  type: SEARCH_WISHLIST_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_WISHLIST_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_WISHLIST_ERROR,
  error: true,
  payload: e,
});

const getUserLikedList= params =>{
  const lists = params.privateData.likedListings;
  return lists;

}

export const searchListings = searchParams => async (dispatch, getState, sdk) => {

  dispatch(searchListingsRequest(searchParams));
//   const { perPage, price, dates, sort, ...rest } = searchParams;
//   const priceMaybe = priceSearchParams(price);
//   const datesMaybe = datesSearchParams(dates);
//   const sortMaybe = sort === config.custom.sortConfig.relevanceKey ? {} : { sort };

const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
const aspectRatio = aspectHeight / aspectWidth;
const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: 10,
  };
  let currentuser =null;
  const cu  =await sdk.currentUser.show().then(res => {
    dispatch(userFetchSuccess);
    currentuser = res.data.data;

    return res
  })

  const uuid = getUserLikedList(currentuser.attributes.profile)
  const str = uuid ? String(uuid) : "";

  return str.length > 0 ? sdk.listings
    .query({
      ids:str,
      include: ['author', 'images'],
      'fields.listing': ['title', 'geolocation', 'price'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    })
    .then(response => {

      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    }) : Promise.resolve([]);
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};




export const loadData = (params) => {
  // const s = getCurrentUser();
  // console.log(s , "get current")
  // Add minStock filter with default value (1), if stock management is in use.
  // This can be overwriten with passed-in query parameters.

  const minStockMaybe = isStockInUse(config) ? { minStock: 1 } : {};
  const originMaybe =  {};

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;

  return searchListings({
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  });
};



export const getCurrentUser = () => (dispatch, getState, sdk) => {
  return dispatch(fetchCurrentUser()).then(() => {
    const currentUser = getState().user.currentUser;
    return currentUser;
  })
  //   const currentLikes =
  //     currentUser?.attributes?.profile?.privateData?.likedListings;
  //   console.log(currentLikes , "================== current user");
  //   const queryParams = {
  //     expand: true,
  //     include: ['profileImage'],
  //     'fields.image': [
  //       'variants.square-small',
  //       'variants.square-small2x',
  //     ],
  //   };

  //   // if listingId already exists in currentLikes, it should be removed from currentLikes
  //   // if user has current likes, merge listingId into current likes
  //   const ifDislike = !!currentLikes?.includes(listingId);
  //   const likedListings = ifDislike
  //     ? currentLikes.filter(id => id !== listingId)
  //     : currentLikes
  //     ? [...currentLikes, listingId]
  //     : [listingId];

  //   return sdk.currentUser
  //     .updateProfile({ privateData: { likedListings } }, queryParams)
  //     .then(response => {
  //       dispatch(updateLikesSuccess(response));

  //       const entities = denormalisedResponseEntities(response);
  //       if (entities.length !== 1) {
  //         throw new Error(
  //           'Expected a resource in the sdk.currentUser.updateProfile response'
  //         );
  //       }
  //       const currentUser = entities[0];

  //       // Update current user in state.user.currentUser through user.duck.js
  //       dispatch(currentUserShowSuccess(currentUser));
  //     })
  //     .catch(e => {
  //       dispatch(updateLikesError(storableError(e)));
  //     });
  // });
}
