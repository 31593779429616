import pick from 'lodash/pick';

import config from '../../config';
import { types as sdkTypes, util as sdkUtil, createImageVariantConfig } from '../../util/sdkLoader';
// import { getStartOf, addTime } from '../../util/dates';
// import { storableError } from '../../util/errors';
// import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
// import { transactionLineItems } from '../../util/api';
// import * as log from '../../util/log';
// import { denormalisedResponseEntities } from '../../util/data';
import { getUserById } from "../../util/api";
// import { TRANSITION_ENQUIRE } from '../../util/transaction';
// import {
//   LISTING_PAGE_DRAFT_VARIANT,
//   LISTING_PAGE_PENDING_APPROVAL_VARIANT,
// } from '../../util/urlHelpers';
// import {
//   fetchCurrentUser,
//   fetchCurrentUserHasOrdersSuccess,
//   currentUserShowSuccess,
// } from '../../ducks/user.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //
export const GET_FOLLOWING_USERS_REQUEST = 'app/FollowingUsers/GET_FOLLOWING_USERS_REQUEST';
export const GET_FOLLOWING_USERS_SUCCESS = 'app/FollowingUsers/GET_FOLLOWING_USERS_SUCCESS';
export const GET_FOLLOWING_USERS_ERROR = 'app/FollowingUsers/GET_FOLLOWING_USERS_ERROR';

// ================ Reducer ================ //

const initialState = {
  followingUsers: [],
  followingUsersInProgress: true,
  followingsUsersError: null
};

const profileFollowingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case GET_FOLLOWING_USERS_REQUEST: {
      return {...state, followingUsersInProgress: true}
    }
    case GET_FOLLOWING_USERS_SUCCESS: {
      return {...state, 
        followingUsers: payload,
        followingUsersInProgress: false
      }
    }
    case GET_FOLLOWING_USERS_ERROR: {
      return {...state, followingUsersInProgress: false, followingsUsersError: payload}
    }
    default:
      return state;
  }
};

export default profileFollowingPageReducer;

// ================ Action creators ================ //
// UPDATE USER FOLLOW
export const getFollowingUsersRequest = () => ({
  type: GET_FOLLOWING_USERS_REQUEST
});
export const getFollowingUsersSuccess = (payload) => ({
  type: GET_FOLLOWING_USERS_SUCCESS,
  payload
});
export const getFollowingUsersError = error => ({
  type: GET_FOLLOWING_USERS_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //
export const getFollowingUsers = (followingIds) => (dispatch, getState, sdk) => {
  dispatch(getFollowingUsersRequest());

  const requests = followingIds.map(id => getUserById({ id }));

  Promise.all(requests).then(response => {
    const followingUsers = response.map(user => Object.assign({}, {
      ...user.data.data,
      image: user.data.included ? {...user.data.included[0]} : {}
    }));
    
    dispatch(getFollowingUsersSuccess(followingUsers))
  }).catch(err => {
    dispatch(getFollowingUsersError(err))
  })
}