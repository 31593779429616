import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import config from '../../config';
import { currentUserShowSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';

// ================ Action types ================ //

export const FETCH_CART_ITEMS_REQUEST = 'app/CartPage/FETCH_CART_ITEMS_REQUEST';
export const FETCH_CART_ITEMS_SUCCESS = 'app/CartPage/FETCH_CART_ITEMS_SUCCESS';
export const FETCH_CART_ITEMS_ERROR = 'app/CartPage/FETCH_CART_ITEMS_ERROR';

export const UPDATE_CART_ITEMS_REQUEST = 'app/ListingPage/UPDATE_CART_ITEMS_REQUEST';
export const UPDATE_CART_ITEMS_SUCCESS = 'app/ListingPage/UPDATE_CART_ITEMS_SUCCESS';
export const UPDATE_CART_ITEMS_ERROR = 'app/ListingPage/UPDATE_CART_ITEMS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchCartItemsInProgress: false,
  fetchCartItemsError: null,
  cartItems: [],
  updateCartItemsError: null,
  updateCartItemsInProgress: false,
};

export default function cartPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CART_ITEMS_REQUEST:
      return { ...state, fetchCartItemsInProgress: true, fetchCartItemsError: null };
    case FETCH_CART_ITEMS_SUCCESS: {
      return {
        ...state, fetchCartItemsInProgress: false,
      };
    }
    case FETCH_CART_ITEMS_ERROR:
      return { ...state, fetchCartItemsInProgress: false, fetchCartItemsError: payload };

    case UPDATE_CART_ITEMS_REQUEST:
      return { ...state, updateCartItemsInProgress: true, updateCartItemsError: null };
    case UPDATE_CART_ITEMS_SUCCESS:
      return { ...state, updateCartItemsInProgress: false };
    case UPDATE_CART_ITEMS_ERROR:
      return { ...state, updateCartItemsInProgress: false, updateCartItemsError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchCartItemsRequest = () => ({ type: FETCH_CART_ITEMS_REQUEST });
const fetchCartItemsSuccess = response => ({
  type: FETCH_CART_ITEMS_SUCCESS, payload: response,
});
const fetchCartItemsError = e => ({
  type: FETCH_CART_ITEMS_ERROR, error: true, payload: e,
});

export const updateCartItemsRequest = params => ({
  type: UPDATE_CART_ITEMS_REQUEST, payload: { params },
});
export const updateCartItemsSuccess = payload => ({
  type: UPDATE_CART_ITEMS_SUCCESS, payload,
});
export const updateCartItemsError = error => ({
  type: UPDATE_CART_ITEMS_ERROR, payload: error, error: true,
});

// ================ Thunks ================ //

export const loadData = () => (dispatch, getState, sdk) => {
  dispatch(fetchCartItemsRequest());

  return dispatch(fetchCurrentUser()).then(() => {
    const currentUser = getState().user.currentUser;
    const currentCartItems = currentUser?.attributes?.profile?.privateData?.cartItems;
    const listingIds = currentCartItems?.map(item => item.listingId).join(',');

    dispatch(fetchCartItemsSuccess(currentCartItems));

    const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
    const aspectRatio = aspectHeight / aspectWidth;
    const apiQueryParams = {
      ids: listingIds,
      include: ['author', 'images', 'currentStock'],
      'fields.listing': ['title', 'price', 'publicData'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`], ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio), ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    };

    return sdk.listings
      .query(apiQueryParams)
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        return response;
      })
      .catch(e => {
        dispatch(fetchCartItemsError(storableError(e)));
        throw e;
      });
  });
};

export const updateCartItems = cartItem => (dispatch, getState, sdk) => {
  dispatch(updateCartItemsRequest());

  const { listingId, quantity } = cartItem;

  const currentUser = getState().user.currentUser;
  const currentCartItems = currentUser?.attributes?.profile?.privateData?.cartItems || JSON.parse(localStorage.getItem('cartItems') || '[]');

  const isListingInCart = !!currentCartItems?.some(item => item.listingId === listingId);
  const isDeleteFromCart = isListingInCart && quantity === 0;
  const cartItems = isDeleteFromCart ? currentCartItems.filter(item => item.listingId !== listingId) : isListingInCart ? (() => {
    const itemsCopy = [...currentCartItems];
    itemsCopy.splice(currentCartItems.findIndex(item => item.listingId === listingId), 1, cartItem);
    return itemsCopy;
  })() : currentCartItems ? [...currentCartItems, cartItem] : [cartItem];

  const queryParams = {
    expand: true,
    include: ['profileImage'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  };

  if (currentUser) {
    return sdk.currentUser
      .updateProfile({ privateData: { cartItems } }, queryParams)
      .then(response => {
        const updatedCartItems = response.data.data?.attributes?.profile?.privateData?.cartItems || [];
        dispatch(updateCartItemsSuccess(updatedCartItems));

        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];

        // Update current user in state.user.currentUser through user.duck.js
        dispatch(currentUserShowSuccess(currentUser));
      })
      .catch(e => {
        dispatch(updateCartItemsError(storableError(e)));
      });
  } else {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
    return Promise.resolve({});
  }
};
