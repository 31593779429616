import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Button, IconEmailAttention, InlineTextButton, NamedLink } from '../../components';

import css from './ModalMissingInformation.module.css';

const ResetPasswordReminder = props => {
  const {
    className,
    user,
    sendResetPasswordEmailInProgress,
    sendResetPasswordEmailError,
    onResendResetPasswordEmail,
  } = props;

  const email = user.id ? <span className={css.email}>{user.attributes.email}</span> : '';

  const [isEmailSent, setIsEmailSent] = useState(false);
  const handleSendEmail = async () => {
    await onResendResetPasswordEmail(user.attributes.email);
    setIsEmailSent(true);
  };

  const resendEmailLink = (
    <InlineTextButton rootClassName={css.helperLink} onClick={handleSendEmail}>
      <FormattedMessage id="ModalMissingInformation.resendEmailLinkText" />
    </InlineTextButton>
  );

  const fixEmailLink = (
    <NamedLink className={css.helperLink} name="ContactDetailsPage">
      <FormattedMessage id="ModalMissingInformation.fixEmailLinkText" />
    </NamedLink>
  );

  const resendErrorMessage = sendResetPasswordEmailError ? (
    <p className={css.error}>
      <FormattedMessage id="ModalMissingInformation.resendFailed" />
    </p>
  ) : null;

  return (
    <div className={className}>
      <IconEmailAttention className={css.modalIcon} />
      <p className={css.modalTitle}>
        <FormattedMessage id="ModalMissingInformation.resetPasswordTitle" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="ModalMissingInformation.resetPasswordText" />
      </p>

      {!isEmailSent ? (
        <>
          <p className={css.modalMessage}>
            <FormattedMessage id="ModalMissingInformation.clickButton" />
          </p>
          <Button
            className={css.sendButton}
            inProgress={sendResetPasswordEmailInProgress}
            onClick={handleSendEmail}
          >
            Send a reset password email
          </Button>
        </>
      ) : (
        <p className={css.modalMessage}>
          <FormattedMessage
            id="ModalMissingInformation.resetPasswordCheckInbox"
            values={{ email }}
          />
        </p>
      )}

      {resendErrorMessage}

      <div className={css.bottomWrapper}>
        <p className={css.helperText}>
          {sendResetPasswordEmailInProgress ? (
            <FormattedMessage id="ModalMissingInformation.sendingEmail" />
          ) : isEmailSent ? (
            <FormattedMessage
              id="ModalMissingInformation.resendEmail"
              values={{ resendEmailLink }}
            />
          ) : null}
        </p>
        <p className={css.helperText}>
          <FormattedMessage id="ModalMissingInformation.fixEmail" values={{ fixEmailLink }} />
        </p>
      </div>
    </div>
  );
};

export default ResetPasswordReminder;
